import {Component, OnDestroy, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {SupplierInvoiceBehaviourSubject} from '../../../../../subjects/supplierInvoice-behaviour-subject';
import {Supplier, SupplierInvoice} from '../../../models/file';
import {ShareDataService} from '../../../services/share-data.service';
import {SupplierService} from '../../../services/supplier.service';
import {UniqueConsignmentBehaviourSubject} from '../../../../../subjects/unique-consignment-reference-subject';
import {BusinessEntity} from '../../../../digi-business-entity/models/business-entity';

/**
 * For this component we need to detect the following:
 * 1. Supplier has been loaded from SupplierInvoice
 * 2. Supplier has been selected from auto complete
 * 3. Supplier has been selected by default due to importer changing
 * 4. Suppliers have been relaoded due to importer being selected
 * 5. Supplier invoice has been changed, we should still be able to select the suppliers
 */
@Component({
  selector: 'digi-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit, OnDestroy {
  @Input() isQuotation: boolean;
  @Input() isFromForwarding: boolean;
  selectedSupplierInvoice: SupplierInvoice;
  suppliers: Supplier[];
  filteredSuppliers = [];
  supplierAddresses = [];
  subscription: Subscription;
  suppliersSubscription: Subscription;
  displaySupplierModal = false;

  constructor(private supplierService: SupplierService,
              private shareDataService: ShareDataService,
              private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject
  ) {
  }

  ngOnInit() {
    // Get the suppliers from the behaviour subject and set it on this.suppliers
    this.subscription = this.supplierInvoiceBehaviourSubject.getSupplierInvoice().subscribe(
      si => {
        if (si) {
          this.selectedSupplierInvoice = si;
          this.selectedSupplierInvoice.supplier = Object.assign({}, this.selectedSupplierInvoice.supplier);
          // if (!this.suppliersSubscription && !this.isFromForwarding) { //Todo : think this may be part of the cause of the UI breaking
          if (!this.suppliersSubscription) {
            this.suppliersSubscription = this.supplierInvoiceBehaviourSubject.getSuppliers().pipe(
              tap(suppliers => {
                this.suppliers = suppliers;
              }),
              switchMap(suppliers =>
                this.supplierService.findSupplierFromSupplierInvoiceAndSuppliers(this.selectedSupplierInvoice, this.suppliers)
              )
            ).subscribe(supplier => {
              this.supplierAddresses = supplier[0].addresses;
            });
          } else {
            if (this.suppliers) {
              this.supplierService.findSupplierFromSupplierInvoiceAndSuppliers(this.selectedSupplierInvoice, this.suppliers)
                .subscribe(supplier => {
                  this.supplierAddresses = supplier[0].addresses;
                });
            }
          }
        }
      }
    );
  }

  search(event) {
    this.filteredSuppliers = [];
    for (let i = 0; i < this.suppliers.length; i++) {
      const importerSupplier: Supplier = this.suppliers[i];
      if (
        importerSupplier.code.toLowerCase().indexOf(event.query.toLowerCase()) === 0 ||
        importerSupplier.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0
      ) {
        this.filteredSuppliers.push(importerSupplier);
      }
    }
  }

  select(supplier) {
    this.onSelectSupplier(supplier);
  }

  onSupplierCreated(businessEntity: BusinessEntity) {
    let supplier = new Supplier();
    this.displaySupplierModal = false;

    if (businessEntity && businessEntity.code) {
      this.suppliers.find(s => {
        if (s.code === businessEntity.code) {
          supplier = s;
          return true;
        }
        return false;
      });
      this.onSelectSupplier(supplier);
    }

  }

  onSelectSupplier(supplier) {
    this.supplierService.findSupplierFromSupplierInvoice(this.selectedSupplierInvoice, supplier)
      .subscribe(result => {
        this.supplierAddresses = result[0].addresses;
      });
  }

  openSupplierModal() {
    this.displaySupplierModal = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.suppliersSubscription.unsubscribe();
  }
}
