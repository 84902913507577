/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invoice-default-values.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../country-of-origin/country-of-origin.component.ngfactory";
import * as i3 from "../country-of-origin/country-of-origin.component";
import * as i4 from "../../services/share-data.service";
import * as i5 from "../../services/country.service";
import * as i6 from "../currency/currency.component.ngfactory";
import * as i7 from "../currency/currency.component";
import * as i8 from "../../services/currency.service";
import * as i9 from "../../../../subjects/clearingfile-behaviour-subject";
import * as i10 from "../purchase-terms/purchase-terms.component.ngfactory";
import * as i11 from "../purchase-terms/purchase-terms.component";
import * as i12 from "../../services/purchase-term.service";
import * as i13 from "./invoice-default-values.component";
import * as i14 from "../../../../subjects/supplierInvoice-behaviour-subject";
var styles_InvoiceDefaultValuesComponent = [i0.styles];
var RenderType_InvoiceDefaultValuesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvoiceDefaultValuesComponent, data: {} });
export { RenderType_InvoiceDefaultValuesComponent as RenderType_InvoiceDefaultValuesComponent };
export function View_InvoiceDefaultValuesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "ui-g form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "digi-country-of-origin", [], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.selectCountryOfOrigin($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CountryOfOriginComponent_0, i2.RenderType_CountryOfOriginComponent)), i1.ɵdid(6, 638976, null, 0, i3.CountryOfOriginComponent, [i4.ShareDataService, i5.CountryService], { country: [0, "country"], isQuotation: [1, "isQuotation"] }, { onSelect: "onSelect" }), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "digi-currency", [], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.selectCurrency($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_CurrencyComponent_0, i6.RenderType_CurrencyComponent)), i1.ɵdid(9, 770048, null, 0, i7.CurrencyComponent, [i4.ShareDataService, i8.CurrencyService, i1.KeyValueDiffers, i9.ClearingFileBehaviourSubject], { currency: [0, "currency"], required: [1, "required"] }, { onSelect: "onSelect" }), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "ui-g-12 ui-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "digi-purchase-terms", [], null, null, null, i10.View_PurchaseTermsComponent_0, i10.RenderType_PurchaseTermsComponent)), i1.ɵdid(12, 638976, null, 0, i11.PurchaseTermsComponent, [i12.PurchaseTermService], { purchaseTerm: [0, "purchaseTerm"], isQuotation: [1, "isQuotation"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedCountry; var currVal_1 = _co.isQuotation; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = _co.selectedCurrency; var currVal_3 = !_co.isQuotation; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = _co.selectedSupplierInvoice.defaultValues.purchaseTerm; var currVal_5 = _co.isQuotation; _ck(_v, 12, 0, currVal_4, currVal_5); }, null); }
export function View_InvoiceDefaultValuesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "digi-invoice-default-values", [], null, null, null, View_InvoiceDefaultValuesComponent_0, RenderType_InvoiceDefaultValuesComponent)), i1.ɵdid(1, 245760, null, 0, i13.InvoiceDefaultValuesComponent, [i12.PurchaseTermService, i14.SupplierInvoiceBehaviourSubject], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvoiceDefaultValuesComponentNgFactory = i1.ɵccf("digi-invoice-default-values", i13.InvoiceDefaultValuesComponent, View_InvoiceDefaultValuesComponent_Host_0, { isQuotation: "isQuotation", isFromForwarding: "isFromForwarding" }, {}, ["*"]);
export { InvoiceDefaultValuesComponentNgFactory as InvoiceDefaultValuesComponentNgFactory };
