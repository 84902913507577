<div class="ui-fluid">
  <div class="ui-g">
    <div class="ui-g-4">
      <p-panel>
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-md-6">
            <p-checkbox binary="true" name="tariffMode" value="tariffMode" label="Tariff mode?"
                        (onChange)="tariffChecked($event)"
                        [(ngModel)]="supplierInvoiceLine.userInterfaceCheckbox.tariffMode"></p-checkbox>
          </div>
          <div class="ui-g-12 ui-md-6">
            <p-checkbox binary="true" name="tariffMode" value="tariffMode" label="Quick Part??"
                        (onChange)="quickPartChecked($event)"
                        [(ngModel)]="supplierInvoiceLine.userInterfaceCheckbox.quickPart"></p-checkbox>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.lineNo" readonly>
                <label>Line No</label>
              </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
                <div class="ui-inputgroup">
                  <span class="ui-float-label">
                      <p-autoComplete id="tariff" [(ngModel)]="tariffCode" field="code"
                                      [suggestions]="filteredTariffCodes"
                                      (completeMethod)="searchTariffCode($event)"
                                      [autoHighlight]="true"
                                      (onSelect)="selectTariffCode($event)"
                                      minLength="3"
                                      required="true"
                                      [disabled]="supplierInvoiceLine.userInterfaceCheckbox.quickPart">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.code}}: {{option.tariffSDesc}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="tariff">Tariff Code</label>
                  </span>
                  <span class="ui-inputgroup-addon">{{supplierInvoiceLine.tariffCode.checkDigit}}</span>
                  <span class="ui-inputgroup-addon"><i (click)="showTariffPopup()" class="fa fa-search"></i></span>
                </div>
               <div *ngIf="!tariffCode.code" class="ui-message ui-messages-error ui-corner-all">
                  This field is required
              </div>
            </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                <p-autoComplete id="partNumber" [(ngModel)]="selectedPartNumber" field="partNumber"
                                [suggestions]="filteredPartNumbers" (completeMethod)="searchPartNumber($event)"
                                [autoHighlight]="true"
                                (onSelect)="selectPartNumber($event)"
                                minLength="1"
                                [disabled]="!supplierInvoiceLine.userInterfaceCheckbox.quickPart">
                  <ng-template let-option pTemplate="item">
                    <div>{{option.partNumber}}</div>
                  </ng-template>
                </p-autoComplete>
                <label for="partNumber">Part Number</label>
              </span>
              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              <span class="ui-inputgroup-addon"><i (click)="showPopup()" class="fa fa-plus"></i></span>
              <div [hidden]="!supplierInvoiceLine.userInterfaceCheckbox.quickPart">
                <span class="ui-inputgroup-addon"><i (click)="showPartUpdatePopup()" class="fa fa-pencil"></i></span>
              </div>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.tariffCode.formula.statisticalUOM"
                           readonly>
                    <label>Statistical UOM</label>
                  </span>
              </div>
                <div *ngIf="!supplierInvoiceLine?.tariffCode?.formula?.statisticalUOM"
                     class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
            </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <digi-country-of-origin (onSelect)="selectCountryOfOrigin($event)"
                                    [country]="supplierInvoiceLine.countryOfOrigin">
            </digi-country-of-origin>
          </div>
          <div class="ui-g-12 ui-md-12">
            <p-checkbox binary="true" name="excludeFromCosting" value="excludeFromCosting" label="Exclude from costing"
                        [(ngModel)]="supplierInvoiceLine.excludeFromCosting"></p-checkbox>
          </div>
        </div>
      </p-panel>
      <br>
      <p-panel header="Additional UOMs">
        <p-table [columns]="cols" [value]="supplierInvoiceLine.additionalUoms"
                 selectionMode="single"
                 dataKey="code">
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width"/>
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">{{ col.header }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
              <td class="ui-resizable-column">{{ rowData?.uomCode }}</td>
              <td>
                <input type="text" style="height: 20px;" pInputText [(ngModel)]="rowData.value"/>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
    </div>
    <div class="ui-g-8">
      <p-panel>
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                <input type="text" pInputText value="{{supplierInvoiceLine.tariffCode.formula.description}}" readonly>
                <label>Formula</label>
                </span>
              </div>
                 <div *ngIf="!supplierInvoiceLine?.tariffCode?.formula?.description"
                      class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
            </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="ui-float-label">
            <textarea [rows]="2" [cols]="30" pInputTextarea
                      [(ngModel)]="supplierInvoiceLine.description"
                      [autoResize]="true">
            </textarea>
              <label>Description</label>
            </span>
          </div>
        </div>
      </p-panel>
      <div class="pt-2">
        <p-panel>
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-12">
              <p-checkbox binary="true" label="No Charge Item"
                          [(ngModel)]="supplierInvoiceLine.noChargeItem"></p-checkbox>&nbsp;
              <p-checkbox binary="true" label="Repair" [(ngModel)]="supplierInvoiceLine.repair"></p-checkbox>&nbsp;
              <p-checkbox binary="true" label="Used Goods" [(ngModel)]="supplierInvoiceLine.usedGoods"></p-checkbox>&nbsp;
              <p-checkbox binary="true" label="DA59" [(ngModel)]="supplierInvoiceLine.da59"></p-checkbox>&nbsp;
              <p-checkbox binary="true" label="Customs VAT" [(ngModel)]="supplierInvoiceLine.customsVAT"></p-checkbox>&nbsp;
            </div>
            <div class="ui-g-12 ui-md-3">
              <span class="md-inputfield">
                <div class="ui-inputgroup">
                   <span class="md-inputfield">
                          <input type="text" pInputText
                                 (ngModelChange)="invoiceGrossValueChanged($event)"
                                 pTooltip="{{supplierInvoiceLine.invoiceGrossValue}}"
                                 [ngModel]="supplierInvoiceLine.invoiceGrossValue"
                                 tooltipPosition="top"
                                 required="true">
                     <label>Invoice Gross Value</label>
                   </span>
                </div>
                 <div *ngIf="!supplierInvoiceLine.invoiceGrossValue" class="ui-message ui-messages-error ui-corner-all">
                  This field is required
              </div>
              </span>
            </div>
            <div class="ui-g-12 ui-md-3">
              <digi-currency [currency]="supplierInvoice?.currency" [readonly]="true"></digi-currency>
            </div>
            <div class="ui-g-12 ui-md-2">
              <div class="ui-inputgroup">
                  <span class="md-inputfield">
                  <input type="text" tooltipPosition="top" pInputText [ngModel]="supplierInvoiceLine.discountPercent"
                         (ngModelChange)="discountPercentChanged($event)"
                         pTooltip="{{supplierInvoiceLine.discountPercent}}">
                    <label>Discount %</label>
                  </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                <input type="text"
                       pInputText
                       (ngModelChange)="discountValueChanged($event)"
                       pTooltip="{{supplierInvoiceLine.discountValue}}"
                       [ngModel]="supplierInvoiceLine.discountValue"
                       tooltipPosition="top">
                   <label>Discount Value</label>
                 </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                <input type="text"
                       pInputText
                       pTooltip="{{supplierInvoiceLine.invoiceValue}}"
                       [(ngModel)]="supplierInvoiceLine.invoiceValue"
                       tooltipPosition="top"
                       readonly>
                   <label>Invoice Value</label>
                 </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"
                           pInputText
                           pTooltip="{{supplierInvoiceLine.customsValue}}"
                           [(ngModel)]="supplierInvoiceLine.customsValue"
                           tooltipPosition="top">
                  <label>Customs Value</label>
                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4" *ngIf="fileType==='imports'">
              <div class="ui-inputgroup">
                  <span class="md-inputfield">
                  <input type="text" tooltipPosition="top"
                         pInputText
                         [ngModel]="supplierInvoiceLine.vdnPercent"
                         (ngModelChange)="vdnPercentChanged($event)"
                         pTooltip="{{supplierInvoiceLine.vdnPercent}}">
                     <label>VDN %</label>
                  </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4">
              <digi-trade-agreement
                [country]="supplierInvoiceLine.countryOfOrigin"
                [selectedTradeAgreement]="supplierInvoiceLine.tradeAgreement"
                (updateTradeAgreement)="updateTradeAgreement($event)"
                (updateROO)="updateROO($event)"
              >
              </digi-trade-agreement>
            </div>
            <div class="ui-g-12 ui-md-4">
               <span class="md-inputfield">
                    <div class="ui-inputgroup">
                      <span class="md-inputfield">
                      <input type="text"
                             pInputText
                             pTooltip="{{supplierInvoiceLine.lineQuantity}}"
                             [(ngModel)]="supplierInvoiceLine.lineQuantity"
                             tooltipPosition="top"
                             required="true">
                        <label>Line</label>
                      </span>
                      <span
                        class="ui-inputgroup-addon">{{supplierInvoiceLine.tariffCode.formula?.statisticalUOM}}</span>
                    </div>
                   <div *ngIf="!file.quotation && !supplierInvoiceLine.lineQuantity" class="ui-message ui-messages-error ui-corner-all">
                  This field is required
              </div>
               </span>
            </div>
            <div class="ui-g-12 ui-md-4">
               <span class="md-inputfield">
                  <div class="ui-inputgroup">
                    <span class="md-inputfield">
                    <input type="text"
                           pInputText
                           pTooltip="{{supplierInvoiceLine.lineWeight}}"
                           [(ngModel)]="supplierInvoiceLine.lineWeight"
                           tooltipPosition="top"
                           required="true">
                      <label>Weight</label>
                    </span>
                    <span class="ui-inputgroup-addon">KG</span>
                  </div>
                 <div *ngIf="!supplierInvoiceLine.lineWeight" class="ui-message ui-messages-error ui-corner-all">
                  This field is required
              </div>
               </span>
            </div>
            <div class="ui-g-12 ui-md-4" *ngIf="rooRequired">
                <span class="md-inputfield">
                  <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.rooNumber" required>
                  <label>R.O.O Number</label>
                  <div *ngIf="!file.quotation && !supplierInvoiceLine.rooNumber" class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                </span>
            </div>
            <div class="ui-g-12 ui-md-4">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                <input type="text" pInputText
                       pTooltip="{{supplierInvoiceLine.clientOrderNo}}"
                       [(ngModel)]="supplierInvoiceLine.clientOrderNo"
                       tooltipPosition="top">
                  <label>Client Order Number</label>
                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                <input type="text"
                       pInputText
                       pTooltip="{{supplierInvoiceLine.additionalClientReference}}"
                       [(ngModel)]="supplierInvoiceLine.additionalClientReference"
                       tooltipPosition="top"
                       readonly>
                  <label>Additional Client Reference</label>
                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4" *ngIf="fileType==='exports' || supplierInvoiceLine?.tradeAgreement?.code?.trim()==='SADC'">
              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="certificateType" [(ngModel)]="supplierInvoiceLine.certificate.type"
                                                  field="label"
                                                  [suggestions]="filteredCertificateTypes"
                                                  (completeMethod)="searchCertificateTypes($event)"
                                                  (onSelect)="selectCertificateType($event)"
                                                  (onClear)="clearCertificate()"
                                                  [dropdown]="true"
                                                  minLength="3"
                                                  required="true">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="certificateType">Certificate Type</label>
                  </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4" *ngIf="fileType==='exports' || supplierInvoiceLine?.tradeAgreement?.code?.trim()==='SADC'">
                              <span class="md-inputfield">
                                <input type="text" pInputText
                                       [(ngModel)]="supplierInvoiceLine.certificate.certificateNo"
                                       required=true>
                                <label>Certificate No.</label>
                              </span>
            </div>
            <div class="ui-g-12 ui-md-4" *ngIf="fileType==='exports'">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"
                           pInputText
                           pTooltip="{{supplierInvoiceLine.toBeCoded}}"
                           [(ngModel)]="supplierInvoiceLine.toBeCoded"
                           tooltipPosition="top">
                  <label>To Be Coded</label>
                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4" *ngIf="fileType==='exports'">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"
                           pInputText
                           pTooltip="{{supplierInvoiceLine.quantity}}"
                           [(ngModel)]="supplierInvoiceLine.quantity"
                           tooltipPosition="top">
                  <label>Quantity</label>
                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4" *ngIf="fileType ==='exports'">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"
                           pInputText
                           pTooltip="{{supplierInvoiceLine.unitPrice}}"
                           [(ngModel)]="supplierInvoiceLine.unitPrice"
                           tooltipPosition="top">
                  <label>Unit Price</label>
                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4" *ngIf="fileType ==='exports' && displayUnitType">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                  <p-dropdown [options]="quantityTypes"
                              [(ngModel)]="supplierInvoiceLine.quantityType"
                              [autoWidth]="false">
                  </p-dropdown>
                  <label>Quantity Unit</label>
                </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g ui-fluid">
                <div class="ui-g-4 ui-md-4">
                              <span class="md-inputfield">
                                <input type="text" pInputText
                                       [(ngModel)]="supplierInvoiceLine.ucrNumber"
                                       required=true>
                                <label>UCR</label>
                              </span>
                </div>
                <ng-container *ngIf="file.importer && file.importer.costingRequired">
                  <div class="ui-g-4 ui-md-4">
                    <span class="md-inputfield">
                      <input type="text" pInputText
                             [(ngModel)]="supplierInvoiceLine.costingValue"
                             required>
                      <label>Costing Value</label>
                      <div *ngIf="!supplierInvoiceLine.costingValue" class="ui-message ui-messages-error ui-corner-all">
                        This field is required
                      </div>
                    </span>
                  </div>
                  <div class="ui-g-4 ui-md-4">
                    <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="costingUom" [(ngModel)]="supplierInvoiceLine.costingUom"
                                                  field="uomCode"
                                                  [autoHighlight]="true"
                                                  [suggestions]="filteredUoms"
                                                  (completeMethod)="searchUoms($event)"
                                                  (onSelect)="selectUom($event)"
                                                  [dropdown]="true"
                                                  minLength="1">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="costingUom">Costing UOM</label>
                  </span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="supplierInvoiceLine.vehicleDetails">
                  <div class="ui-g-12 ui-md-3">
              <span class="md-inputfield">
                <div class="ui-inputgroup">
                   <span class="md-inputfield">
                          <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.vehicleDetails.vinNumber"
                                 readonly>
                    <label>VIN No.</label>
                   </span>
                </div>
              </span>
                  </div>
                  <div class="ui-g-12 ui-md-3">
                    <span class="md-inputfield">
                <div class="ui-inputgroup">
                   <span class="md-inputfield">
                          <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.vehicleDetails.engineNumber"
                                 readonly>
                    <label>Engine No.</label>
                   </span>
                </div>
              </span>
                  </div>
                  <div class="ui-g-12 ui-md-2">
                    <div class="ui-inputgroup">
                  <span class="md-inputfield">
                          <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.vehicleDetails.colour"
                                 readonly>
                    <label>Colour</label>
                   </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-4">
                    <div class="ui-inputgroup">
                 <span class="md-inputfield">
                          <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.vehicleDetails.model"
                                 readonly>
                    <label>Model</label>
                   </span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
    <div [ngClass]="{'ui-g-8': isWarehouse(),'ui-g-12': !isWarehouse()&& (!isXE() || !isZE),'ui-g-6': isXE() || isZE()}">
      <digi-additional-schedule-tariff [selectedAdditionalScheduleTariffs]="additionalScheduleTariffs"
                                       [clearingInstruction]="clearingInstruction" [tariffCode]="tariffCode" [countryOfOriginCode]="supplierInvoiceLine.countryOfOrigin.code?.trim()" (selectedAdditionalScheduleTariffsChange)="updateAdditionalSchedules($event)"></digi-additional-schedule-tariff>
    </div>
    <div *ngIf="isWarehouse()||isXE() || isZE()" [ngClass]="{'ui-g-4': isWarehouse(),'ui-g-3': isXE() || isZE()}">
      <div class="ui-g-12">
        <p-panel header="Package Details">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-12">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.noOfPacks">
                    <label>No of Packs</label>
                  </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.itemsPerPack">
                    <label>Items per Pack</label>
                  </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                  <span class="ui-float-label">
                    <p-autoComplete id="packType" [(ngModel)]="supplierInvoiceLine.packType"
                                    field="label"
                                    [dropdown]="true"
                                    [suggestions]="filteredPackTypes"
                                    (completeMethod)="searchPackTypes($event)"
                                    (onSelect)="selectPackType($event)"
                                    [autoHighlight]="true" minLength="1"
                                    required="true">
                      <ng-template let-option pTemplate="item">
                        <div>{{option.code}} :{{option.description}}</div>
                      </ng-template>
                    </p-autoComplete>
                  <label for="packType">Pack Type</label>
                </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.caseNumber">
                    <label>Case Number</label>
                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
    <div *ngIf="isXE() || isZE()" class="ui-g-3">
      <div class="ui-g-12">
        <p-panel header="Original Bill of Entry Details">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-12">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.previousBoeDetails.customsBOENo">
                    <label>MRN</label>
                  </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield">
                    <span class="ui-float-label">
                      <span class="md-inputfield">
                        <p-inputMask id="mawbDate" mask="99/99/9999"
                                     slotChar="dd/mm/yyyy"
                                     [(ngModel)]="supplierInvoiceLine.previousBoeDetails.date">

                        </p-inputMask>
                        <label for="mawbDate">Customs BOE Date</label>
                      </span>
                    </span>
                  </span>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.previousBoeDetails.lineNo">
                    <label>Line No.</label>
                  </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.previousBoeDetails.cpc">
                    <label>Purpose Code / CPC</label>
                  </span>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" pInputText [(ngModel)]="supplierInvoiceLine.previousBoeDetails.noOfPacks">
                    <label>Items per Pack</label>
                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
    <div class="ui-g-12">
      <digi-permits-table [permits]="permits"
                          [selectedPermit]="supplierInvoiceLine.permit"
                          (selectPermit)="selectPermit($event)"
                           ></digi-permits-table>
    </div>
    <div class="ui-g-12">
      <p-panel>
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="md-inputfield">
                  <input type="text"
                         pInputText
                         value="{{supplierInvoiceLine.tariffCode.description}}">
                  <label>Description</label>
                </span>
              </div>
            </span>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="ui-float-label">
              <textarea
                [rows]="5"
                [cols]="30"
                [(ngModel)]="supplierInvoiceLine.fullDescription"
                pInputTextarea
                autoResize="true">
              </textarea>
              <label>Full Description</label>
            </span>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
  <p-dialog [header]="isPartNumberUpdate ? 'Update Part' : 'New Part'" [(visible)]="openPartNumberDialog" [modal]="true"
            [responsive]="true" [width]="800"
            [height]="400"
            [contentStyle]="{'height':'300px'}"
            [minWidth]="200" [minY]="70"
            [maximizable]="true" [baseZIndex]="10000">
    <div class="ui-fluid">
      <div class="ui-g">
        <div class="ui-g-12">
          <digi-part [part]="selectedPartNumber" [updatePart]="isPartNumberUpdate" [fileType]="fileType" [businessEntity]="fileType=='imports'?file.importer:file.exporter"></digi-part>
        </div>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="savePart()"
              [label]="isPartNumberUpdate ? 'Update' : 'Save'"
              class="ui-button-secondary"></button>
      <button type="button" pButton icon="pi pi-close" (click)="openPartNumberDialog=false;isPartNumberUpdate=false;"
              label="Close"
              class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
  <p-dialog header="Tariff Search" [(visible)]="showTariffDialog" [modal]="true"
            [responsive]="true" [width]="800"
            [height]="400"
            [contentStyle]="{'height':'300px'}"
            [minWidth]="200" [minY]="70"
            [maximizable]="true" [baseZIndex]="10000">
    <div class="ui-fluid">
      <div class="ui-g">
        <div class="ui-g-12">
          <div class="ui-g-12 ui-md-12">
            <div class="ui-g-4 ui-md-4">
            <span class="md-inputfield">
              <input type="text" pInputText [(ngModel)]="tariff">
              <label>Tariff Code</label>
            </span>
            </div>
            <div class="ui-g-2 ui-md-2">
              <button type="button" pButton icon="fa fa-search" (click)="searchTariffs()" label="Search"></button>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <p-tree [value]="tariffCodeTree" selectionMode="single" [(selection)]="selectedTariff" [style]="{'text-align':'left'}"></p-tree>
          </div>
        </div>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check"
              label="Select"
              class="ui-button-secondary" (click)="selectTariff()"></button>
      <button type="button" pButton icon="pi pi-close" (click)="showTariffDialog=false"
              label="Close"
              class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
</div>



