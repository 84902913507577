import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ClearingFile, Importer} from '../../../models/clearingFile';
import {ImporterService} from '../../../services/importer.service';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {ShareDataService} from '../../../services/share-data.service';
import {Subscription} from 'rxjs';
import {CompanyService} from '../../../services/company.service';
import {Branch} from '../../../models/company';
import {BusinessEntity} from '../../../../digi-business-entity/models/business-entity';
import {SaveClearingFile} from '../../../saveClearingFile';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../../../subjects/supplierInvoice-behaviour-subject';
import {SupplierInvoiceLineBehaviourSubject} from '../../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {SupplierInvoice, SupplierInvoiceLine, TransportDocument} from '../../../models/file';

@Component({
  selector: 'digi-importer',
  templateUrl: './importer.component.html',
  styleUrls: ['./importer.component.css']
})
export class ImporterComponent implements OnInit, OnDestroy {
  clearingFile: ClearingFile;
  @Output() selected = new EventEmitter();
  @Output() changed = new EventEmitter();
  importerAddresses = [];
  filteredImporters: Importer[];
  subscribe: Subscription;
  displayImporterModal = false;
  importers: Importer[];
  selectedTransportDocument: TransportDocument;
  selectedSupplierInvoice: SupplierInvoice;
  selectedSupplierInvoiceLine: SupplierInvoiceLine;

  constructor(private importerService: ImporterService,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private shareDataService: ShareDataService,
              private companyService: CompanyService,
              private onSaveClearingFile: SaveClearingFile,
              private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
              private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
  ) {
  }

  ngOnInit() {
    this.subscribe = this.clearingFileBehaviourSubject
      .getClearingFile()
      .subscribe(cf => {
        this.clearingFile = cf;
        if (!this.clearingFile.importer.code && !this.clearingFile.fromForwarding) {
          this.companyService.getCompany().subscribe(companies => {
            if (companies && companies.length && !this.clearingFile.importer.code) {
              const branch: Branch = this.clearingFile.branch ? companies[0].branches
                .find(el => el.code === this.clearingFile.branch) : companies[0].branches[0];
              if (branch.importerCode && branch.importerCode.trim() !== '') {
                this.importerService.findImporterNameOrCodeStartsWith(branch.importerCode).subscribe(data => {
                  if (data && data.length) {
                    this.select(data[0]);
                  }
                });
              }
            }
          });
        } else {
          this.findImporterFromRole(this.clearingFile);
        }
      });
    this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: TransportDocument) => (this.selectedTransportDocument = td));

    this.supplierInvoiceBehaviourSubject.getSupplierInvoice()
      .subscribe((si: SupplierInvoice) => (this.selectedSupplierInvoice = si));

    this.supplierInvoiceLineBehaviourSubject.getSupplierInvoiceLine()
      .subscribe((line: SupplierInvoiceLine) => this.selectedSupplierInvoiceLine = line);
  }

  search(event) {
    this.importerService.findImporterNameOrCodeStartsWith(event.query).subscribe(data => {
      this.filteredImporters = data;
    });
  }

  select(value) {
    this.clearingFile.importer = Object.assign({}, value);
    this.findImporterFromRole(this.clearingFile);
    // this.selected.emit(value);
  }


  private findImporterFromRole(clearingFile) {
    this.importerService.findImporterFromClearingFile(clearingFile)
      .subscribe(importerData => {
        this.importerAddresses = importerData[0].addresses;
      });
  }

  onImporterCreated(businessEntity: BusinessEntity) {
    let importer = new Importer();
    this.displayImporterModal = false;

    if (businessEntity && businessEntity.code) {
      this.importers.find(i => {
        if (i.code === businessEntity.code) {
          importer = i;
          return true;
        }
        return false;
      });
      this.onSelectImporter(importer);
    }

  }

  onSelectImporter(importer) {
    this.importerService.findImporterNameOrCodeStartsWith(importer.name)
      .subscribe(result => {
        this.importerAddresses = result[0].addresses;
      });
  }

  openImporterModal() {
    this.displayImporterModal = true;
  }

  openEditImporterModal() {
    this.onSaveClearingFile.saveClearingFile(
      this.clearingFile,
      this.selectedTransportDocument,
      this.selectedSupplierInvoice,
      this.selectedSupplierInvoiceLine,
    ).subscribe(
      value => {
        this.displayImporterModal = true;
        this.clearingFile.importer.isEdited = true;
      });
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }
}
