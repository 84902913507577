<p-tabView orientation="left" styleClass="stepMenu" [activeIndex]="activeIndex">
  <p-tabPanel header="Generate" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <digi-forwarding-file-header-section>
          </digi-forwarding-file-header-section>
        </div>
        <div *ngIf="disableButton" class="ui-g-12">
          <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
        </div>
        <div class="ui-g-12 ui-md-12">
          <ng-container>
            <div class="ui-g-1">
              <div class="ui-g-12">
                <button pButton type="button" pTooltip="Generate Manifest"
                        tooltipPosition="top"
                        class="ui-button-secondary col-sm-12"
                        (click)="generateManifest()" icon="fa fa-print" [disabled]="disableButton"></button>
              </div>
              <div class="ui-g-12">
                <button pButton type="button" pTooltip="Send Manifest"
                        tooltipPosition="top"
                        class="ui-button-secondary col-sm-12"
                        (click)="sendManifestToSARS()" icon="fa fa-paper-plane"
                        [disabled]="hasSuccessfulResponseOrIsAwaitingResponse() || disableButton"></button>
              </div>
              <div class="ui-g-12">
                <button pButton type="button" pTooltip="Clear Manifest"
                        tooltipPosition="top"
                        class="ui-button-secondary col-sm-12"
                        (click)="createClearingFilesFromManifest()" icon="fa fa-file"
                        [disabled]="disableButton"></button>
<!--                        [disabled]="hasSuccessfulResponseOrIsAwaitingResponse() || disableButton"></button>-->
              </div>
            </div>
            <div class="ui-g-11">
              <div class="ui-g-4">
                <p-panel header="Master Bill Details">
                  <div class="ui-fluid">
                    <div class="ui-g form-group">
                      <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <input type="text"
                     class="ui-inputgroup"
                     pInputText
                     [(ngModel)]="forwardingFile.masterBill.mawbNo"
                     required>
                 <label>MAWB No.</label>
            </span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
<span class="md-inputfield">
    <div class="ui-inputgroup">
            <span class="ui-float-label">
            <p-autoComplete id="cuscarType"
                            [(ngModel)]="forwardingFile.cuscarType"
                            field="label"
                            [suggestions]="filteredCuscarTypes"
                            (completeMethod)="searchCuscarType($event)"
                            [autoHighlight]="true"
                            (onSelect)="selectCuscarType($event)"
                            minLength="2"
                            [dropdown]="true"
            >
              <ng-template let-option pTemplate="item">
                <div>{{option.label}}</div>
              </ng-template>
            </p-autoComplete>
             <label for="cuscarType">Cuscar Type</label>
            </span>
    </div>
    </span>
                      </div>
                      <div class="ui-g-12 ui-md-12">
            <span class="ui-float-label">
            <p-dropdown [options]="manifestTypeOptions"
                        [(ngModel)]="forwardingFile.manifestType"
                        [autoWidth]="false" [style]="{'height':'25px'}">
              <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
                          <ng-template let-item pTemplate="item">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
            </p-dropdown>
             <label>Manifest Type</label>
          </span>
                      </div>
                      <div class="ui-g-12 ui-md-12" *ngIf="forwardingFile.cusres && forwardingFile.cusres.customsStatus === 'RECEIVED_AND_UPDATED'">
                        <span class="ui-float-label">
            <p-dropdown [options]="manifestAmendmentOrCancellationOptions"
                        [(ngModel)]="forwardingFile.manifestAmendmentOrCancellation"
                        [autoWidth]="false" [style]="{'height':'35px'}">
              <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
                          <ng-template let-item pTemplate="item">
        <span style="vertical-align:middle; text-transform: capitalize">{{format(item.value)}}</span>
    </ng-template>
            </p-dropdown>
         <label>Manifest Amendment or Cancellation</label>
          </span>
                      </div>

                    </div>
                  </div>
                </p-panel>
              </div>
            </div>
            <div class="ui-g-12">
              <div *ngIf="pdfSrc" class="card card-w-title">
                <div class="ui-g">
                  <div class="ui-g-3 ui-g-offset-9">
                    <button pButton type="button" label="Print/Download" (click)="openLink()"></button>
                  </div>
                  <pdf-viewer class="ui-g-12 ui-md-12"
                              [src]="pdfSrc"
                              [render-text]="true"
                              [original-size]="true"
                              [show-all]="true"
                              [stick-to-page]="true"
                              (after-load-complete)="disableButton=false"
                  ></pdf-viewer>
                </div>

              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Confirm EDI">
    <ng-template pTemplate="content">
      <div class="container-fluid test-inner-border">
        <div class="ui-fluid">
          <div class="ui-g">
            <div class="ui-g-12">
              <button pButton type="button" label="Refresh" (click)="refresh()" class="col-sm-2"></button>
            </div>

            <div class="ui-g-12">
              <div class="ui-g form-group">
                <div class="ui-g-4 ui-md-4">
            <span class="md-inputfield">
              <input type="text" pInputText value="{{this.forwardingFile?.masterBill?.mawbNo}}"
                     [disabled]="true" class="disabled-status">
              <label>MAWB NO.</label>
            </span>
                </div>
                <div class="ui-g-4 ui-md-4">
            <span class="md-inputfield">
              <input type="text" pInputText
                     value="{{cusres.customsStatus}}" [disabled]="true" class="disabled-status">
              <label>STATUS</label>
            </span>
                </div>
              </div>
            </div>
            <div *ngIf="cusres.errorDetails && cusres.errorDetails.length>0" class="ui-g-12 ui-md-12">
              <p-table [columns]="colsForErrors" [value]="errors"
                       [rows]="5"
                       pColumnHeaders="ERRORS">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns" [style.width]="col.width">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns">
                      {{rowData[col.field]}}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="ui-g-12">
              <p-table [columns]="colsForCuscar" [value]="cuscars"
                       dataKey="ediMessageCustomsObject.status" [paginator]="true" [rows]="1"
                       pColumnHeaders="CUSRES">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width: 3em"></th>
                    <th *ngFor="let col of columns">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex"
                             let-expanded="expanded" let-columns="columns">
                  <tr>
                    <td>
                      <a href="#" [pRowToggler]="rowData">
                        <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                      </a>
                    </td>
                    <td *ngFor="let col of columns">
                      {{rowData}}
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-rowData
                             let-rowIndex="rowIndex" let-columns="columns">
                  <tr>
                    <td [attr.colspan]="columns.length + 1">
                      <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                        <div class="ui-g-12 ui-md-9">
                          <div class="ui-g">
                            <div class="ui-g-12">
                              <pre>{{rowData}}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <p-message *ngIf="controlFailed" severity="error" text="The Contrl message has been rejected."></p-message>
            <div class="ui-g-12">
              <p-table [columns]="colsForContrl" [value]="contrl.messages"
                       dataKey="ediMessageCustomsObject.status" [paginator]="true" [rows]="1"
                       pColumnHeaders="CUSRES">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width: 3em"></th>
                    <th *ngFor="let col of columns">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex"
                             let-expanded="expanded" let-columns="columns">
                  <tr>
                    <td>
                      <a href="#" [pRowToggler]="rowData">
                        <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                      </a>
                    </td>
                    <td *ngFor="let col of columns">
                      {{rowData}}
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-rowData
                             let-rowIndex="rowIndex" let-columns="columns">
                  <tr>
                    <td [attr.colspan]="columns.length + 1">
                      <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                        <div class="ui-g-12 ui-md-9">
                          <div class="ui-g">
                            <div class="ui-g-12">
                              <pre>{{rowData}}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div class="ui-g-12">
              <p-table [columns]="colsForCusres" [value]="cusres.messages"
                       dataKey="ediMessageCustomsObject.status" [paginator]="true" [rows]="4"
                       pColumnHeaders="CUSRES">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width: 3em"></th>
                    <th *ngFor="let col of columns">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex"
                             let-expanded="expanded" let-columns="columns">
                  <tr>
                    <td>
                      <a href="#" [pRowToggler]="rowData">
                        <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                      </a>
                    </td>
                    <td *ngFor="let col of columns">
                      {{rowData}}
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-rowData
                             let-rowIndex="rowIndex" let-columns="columns">
                  <tr>
                    <td [attr.colspan]="columns.length + 1">
                      <div class="ui-g ui-fluid" style="font-size:16px;padding:20px">
                        <div class="ui-g-12 ui-md-9">
                          <div class="ui-g">
                            <div class="ui-g-12">
                              <pre>{{rowData}}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
<p-dialog header="Generated Documents" [(visible)]="display" [modal]="true"
          [responsive]="true" [width]="400"
          [minWidth]="200" [minY]="70"
          [maximizable]="true" [baseZIndex]="10000">
  <ng-template pTemplate="content" [ngIf]="generatedFiles">
    <li *ngFor="let generatedDocument of generatedFiles">
      <a class="uploaded-file-color"
         [href]="generatedDocument._links.self.href" target="_blank">{{generatedDocument.fileName}}</a>
    </li>
  </ng-template>
  <p-footer>
    <button type="button" pButton icon="pi pi-close" (click)="display=false"
            label="Close"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
