import { EMPTY } from 'rxjs';
import { AppConfig } from '../../app.config.module';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Address, Country, Currency, PurchaseTerm, Supplier } from '../models/file';
import { SupplierInvoiceBehaviourSubject } from '../../../subjects/supplierInvoice-behaviour-subject';
import { CountryService } from './country.service';
import { NotificationService } from './notification.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../subjects/supplierInvoice-behaviour-subject";
import * as i2 from "./country.service";
import * as i3 from "@angular/common/http";
import * as i4 from "./notification.service";
import * as i5 from "../../app.config.module";
var SupplierService = /** @class */ (function () {
    function SupplierService(supplierInvoiceBehaviourSubject, countryService, http, messageService, config) {
        this.supplierInvoiceBehaviourSubject = supplierInvoiceBehaviourSubject;
        this.countryService = countryService;
        this.http = http;
        this.messageService = messageService;
        this.config = config;
    }
    SupplierService.prototype.findSupplierByCode = function (foundSupplier) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode="
            + foundSupplier + '&roleType=21' + '&projection=flattenedSupplier')
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    SupplierService.prototype.findSupplierFromSupplierInvoiceAndSuppliers = function (supplierInvoice, suppliers) {
        if (supplierInvoice.supplier.code) {
            return this.findSupplierFromSupplierInvoice(supplierInvoice, supplierInvoice.supplier, false);
        }
        if (suppliers && suppliers.length === 1) {
            return this.findSupplierFromSupplierInvoice(supplierInvoice, suppliers[0]);
        }
        if (!supplierInvoice.defaultValues.purchaseTerm || !supplierInvoice.defaultValues.purchaseTerm.code) {
            supplierInvoice.defaultValues.purchaseTerm = new PurchaseTerm();
        }
        supplierInvoice.defaultValues.rooNumber = undefined;
        this.updateCountry(new Country());
        this.updateCurrency(new Currency());
        return EMPTY;
    };
    SupplierService.prototype.findSupplierFromSupplierInvoice = function (supplierInvoice, supplier, updateSupplier) {
        var _this = this;
        if (updateSupplier === void 0) { updateSupplier = true; }
        if (supplierInvoice) {
            return this.findSupplierByCode(supplier.code).pipe(tap(function (supplierData) {
                if (updateSupplier) {
                    supplierInvoice.supplier = new Supplier();
                    supplierInvoice.supplier.vdnWarning = supplier.vdnWarning;
                    supplierInvoice.supplier.vdnNumber = supplier.vdnNumber;
                    supplierInvoice.supplier.vdnMarkup = supplier.vdnMarkup;
                    supplierInvoice.supplier.customsCode = supplier.customsCode;
                    supplierInvoice.supplier.label = supplier.label;
                    supplierInvoice.supplier.name = supplier.name;
                    supplierInvoice.supplier.code = supplier.code;
                    supplierInvoice.supplier.defaultValuationCode = supplier.defaultValuationCode;
                    supplierInvoice.supplier.defaultValuationMethod = supplier.defaultValuationMethod;
                    console.log(supplierInvoice.supplier);
                    console.log(supplierData);
                    if (supplierData[0].country) {
                        supplierInvoice.defaultValues.country.name = supplierData[0].country.name;
                        supplierInvoice.defaultValues.country.code = supplierData[0].country.code;
                        supplierInvoice.defaultValues.country.label = supplierData[0].country.label;
                    }
                    if (supplierData[0].currency) {
                        supplierInvoice.currency.code = supplierData[0].currency.code;
                        supplierInvoice.currency.name = supplierData[0].currency.name;
                        supplierInvoice.currency.label = supplierData[0].currency.label;
                        supplierInvoice.currency = Object.assign({}, supplierInvoice.currency);
                    }
                    if (supplier.defaultPurchaseTerm) {
                        supplierInvoice.defaultValues.purchaseTerm.description = supplier.defaultPurchaseTerm.description;
                        supplierInvoice.defaultValues.purchaseTerm.code = supplier.defaultPurchaseTerm.code;
                        supplierInvoice.defaultValues.purchaseTerm.label = supplier.defaultPurchaseTerm.label;
                        supplierInvoice.defaultValues.purchaseTerm = Object.assign({}, supplier.defaultPurchaseTerm);
                    }
                    if (supplierData[0].rooNumber) {
                        supplierInvoice.defaultValues.rooNumber = supplierData[0].rooNumber;
                    }
                    else {
                        supplierInvoice.defaultValues.rooNumber = null;
                    }
                }
                if (supplierData && (!supplierInvoice.supplier.address || !supplierInvoice.supplier.address.line1)) {
                    var supplierAddresses = supplierData[0].addresses;
                    var supplierAddress = void 0;
                    if (supplierAddresses.length === 1) {
                        supplierAddress = supplierAddresses[0];
                    }
                    else {
                        supplierAddress = supplierAddresses.find(function (address) {
                            var addressType = address.addressTypes.find(function (addressType) {
                                return addressType.id === 'CUS';
                            });
                            if (addressType) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        });
                    }
                    if (supplierAddress) {
                        supplierInvoice.supplier.address = new Address();
                        supplierInvoice.supplier.address.line1 = supplierAddress.line1;
                        supplierInvoice.supplier.address.line2 = supplierAddress.line2;
                        supplierInvoice.supplier.address.line3 = supplierAddress.line3;
                        supplierInvoice.supplier.address.line4 = supplierAddress.line4;
                        supplierInvoice.supplier.address.zipCode = supplierAddress.zipCode;
                    }
                }
                _this.loadDefaultsForSupplier(supplierInvoice);
            }));
        }
        else {
            supplierInvoice.defaultValues.purchaseTerm = new PurchaseTerm();
            supplierInvoice.defaultValues.rooNumber = undefined;
            this.updateCountry(new Country());
            this.updateCurrency(new Currency());
            return EMPTY;
        }
    };
    SupplierService.prototype.loadDefaultsForSupplier = function (supplierInvoice) {
        var _this = this;
        if (supplierInvoice.defaultValues.country && supplierInvoice.defaultValues.country.code) {
            this.countryService.getCountry(supplierInvoice.defaultValues.country.code).subscribe(function (data) {
                supplierInvoice.defaultValues.country.tradeAgreements = data['tradeAgreements'];
                supplierInvoice.defaultValues.country.name = data['name'];
                supplierInvoice.defaultValues.country.code = data['code'];
                supplierInvoice.defaultValues.country.label = data['label'];
                supplierInvoice.defaultValues.country.defaultTradeAgreement = data['defaultTradeAgreement'];
            }, function (error) {
                _this.messageService.errorNotify(error);
            }, function () {
                _this.updateCountry(supplierInvoice.defaultValues.country);
            });
        }
        if (supplierInvoice.currency) {
            this.updateCurrency(supplierInvoice.currency);
        }
    };
    SupplierService.prototype.updateCountry = function (country) {
        this.supplierInvoiceBehaviourSubject.addCountry(country);
    };
    SupplierService.prototype.updateCurrency = function (currency) {
        this.supplierInvoiceBehaviourSubject.addCurrency(currency);
    };
    SupplierService.ngInjectableDef = i0.defineInjectable({ factory: function SupplierService_Factory() { return new SupplierService(i0.inject(i1.SupplierInvoiceBehaviourSubject), i0.inject(i2.CountryService), i0.inject(i3.HttpClient), i0.inject(i4.NotificationService), i0.inject(i5.APP_CONFIG)); }, token: SupplierService, providedIn: "root" });
    return SupplierService;
}());
export { SupplierService };
