<p-dialog header="Generated HAWB Report" [(visible)]="display" [modal]="true"
          [responsive]="true" [width]="600"
          [minWidth]="200" [minY]="70"
          [maximizable]="true" [baseZIndex]="10000">
  <ng-template pTemplate="content" [ngIf]="generatedFiles">
    <li *ngFor="let generatedDocument of generatedFiles">
      <a class="uploaded-file-color"
         [href]="generatedDocument._links.self.href" target="_blank">{{generatedDocument.fileName}}</a>
    </li>
  </ng-template>
  <p-footer>
    <button type="button" pButton icon="pi pi-close" (click)="display=false"
            label="Close"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-tabView orientation="left" styleClass="stepMenu" (onChange)="handleIndexChange($event.index)"
           [activeIndex]="activeIndex">

  <digi-tracking [display]="displayTrackingDialog"
                 (displayChange)="displayTrackingDialog=$event"
                 (save)="saveForwardingFile()"
                 (sendParcelUpdate)="sendUpdate($event)"
                 (updateHAWBsArrivedAtJNBOPSFields)="updateReceivedAtJNOPSFieldsOnEachHouseBill()"
                 (updateHAWBsReceivedAtAOTIAFields)="updateReceivedAtORTIAFieldsOnEachHouseBill()"
                 [trackingDetails]="transportDocumentToTrack?.trackingDetails"
                 [isHouseBill]="isHouseBill"
                 [displayTrackingProgressBar]="showTrackingProgressBar"
                 [currentStatus]="currentStatus"
                 *ngIf="displayTrackingDialog"
  >
  </digi-tracking>

  <p-tabPanel [header]="isTransportMethodCodeSameAs('air') ? 'MAWB' : 'Ocean BOL'" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <digi-forwarding-file-header-section [transportMethodCode]="forwardingFile.transportMethodCode"
                                               (add)="createNewFile()">
          </digi-forwarding-file-header-section>
        </div>
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g">
            <div *ngIf="showProgressBar" class="ui-g-12">
              <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
            </div>
            <div *ngIf="isTransportMethodCodeSameAs('air')" class="ui-g-12">
              <p-fileUpload (onSelect)="selectFiles($event)"
                            (uploadHandler)="uploadManifest()"
                            [customUpload]="true"
                            accept=".xls,.xlsx"
              >
              </p-fileUpload>
            </div>
            <div class="ui-g-12">
              <button pButton type="button" label="Tracking" icon="fa fa-plus"
                      (click)="track(forwardingFile.masterBill, false)"
                      *ngIf="showTrackingButton && config.enableTracking"
              ></button>
            </div>
            <div class="ui-g-4">
              <p-panel [header]="isTransportMethodCodeSameAs('air') ? 'Flight Information':'Voyage Information'">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <ng-container *ngIf="isTransportMethodCodeSameAs('air')">
                          <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="airline" class="ng-dirty ng-invalid"
                                                [(ngModel)]="forwardingFile.masterBill.airline" field="name"
                                                [suggestions]="filteredAirlines"
                                                (completeMethod)="searchAirline($event)"
                                                [autoHighlight]="true" minLength="2"
                                                (onSelect)="selectAirline($event)"
                                                required=true>
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.code}}: {{option.name}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label for="airline">Airline</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </div>
                            <div *ngIf="!forwardingFile?.masterBill?.airline?.code"
                                 class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                          </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="master-airline" class="ng-dirty ng-invalid"
                                                [(ngModel)]="forwardingFile.masterBill.masterAirline" field="name"
                                                [suggestions]="filteredAirlines"
                                                (completeMethod)="searchAirline($event)"
                                                [autoHighlight]="true" minLength="2"
                                                (onSelect)="selectMasterAirline($event)"
                                                required=true>
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.code}}: {{option.name}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label for="master-airline">Master CC</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </div>
                            <div *ngIf="!forwardingFile?.masterBill?.masterAirline?.code"
                                 class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                          </span>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="isTransportMethodCodeSameAs('sea')">
                          <div class="ui-g-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="oceanLine"
                                                [(ngModel)]="forwardingFile.masterBill.oceanLineDetails.oceanLine"
                                                field="label"
                                                [suggestions]="filteredOceanLines"
                                                (completeMethod)="searchOceanLines($event)"
                                                [autoHighlight]="true"
                                                minLength="2"
                                                required=true>
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.code}}:{{option.name}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label for="oceanLine">Master Cargo Carrier</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </span>
                          </div>
                          <div class="ui-g-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="carrierCode"
                                                [(ngModel)]="forwardingFile.masterBill.oceanLineDetails.carrierCode"
                                                field="label"
                                                [suggestions]="filteredOceanLines"
                                                (completeMethod)="searchOceanLines($event)"
                                                [autoHighlight]="true" minLength="2"
                                                required=true>
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.code}}:{{option.name}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label for="oceanLine">Carrier</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </span>
                          </div>
                        </ng-container>
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span
                                  *ngIf="isTransportMethodCodeSameAs('air') && forwardingFile.masterBill.masterAirline"
                                  class="ui-inputgroup-addon">
                                  {{forwardingFile.masterBill.masterAirline?.prefix}}
                                </span>
                                <span class="md-inputfield">
                                  <input type="text" pInputText id="masterBillMawbNo"
                                         [(ngModel)]="forwardingFile.masterBill.mawbNo">
                                  <label>{{ isTransportMethodCodeSameAs('air') ? 'MAWB No.' : 'Ocean BOL No.' }}</label>
                                </span>
                              </div>
                              <div *ngIf="!forwardingFile?.masterBill?.mawbNo"
                                   class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText id="masterBillSubMawbNo"
                                         [(ngModel)]="forwardingFile.masterBill.subMAWBNo">
                                  <label>{{isTransportMethodCodeSameAs('air') ? 'Sub-MAWB No.' : 'Sub-Ocean BOL No.'}}</label>
                                </span>
                              </div>
                            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask id="mawbDate"
                                               mask="99/99/9999"
                                               slotChar="dd/mm/yyyy"
                                               [(ngModel)]="forwardingFile.masterBill.mawbDate">
                                  </p-inputMask>
                                  <label
                                    for="mawbDate">{{ isTransportMethodCodeSameAs('air') ? 'MAWB Date.' : 'SOB Date'}}</label>
                                </span>
                              </span>
                               <div *ngIf="!forwardingFile?.masterBill?.mawbDate"
                                    class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                            </span>
                        </div>
                        <ng-container *ngIf="isTransportMethodCodeSameAs('air')">
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText id="masterBillFlightNo"
                                         [(ngModel)]="forwardingFile.masterBill.flight">
                                  <label>Flight No.</label>
                                </span>
                              </div>
                               <div *ngIf="!forwardingFile?.masterBill?.flight"
                                    class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                            </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText id="masterBillAircraftRegNo"
                                         [(ngModel)]="forwardingFile.masterBill.aircraftRegNo">
                                  <label>Aircraft Reg No</label>
                                </span>
                              </div>
                            </span>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="isTransportMethodCodeSameAs('sea')">
                          <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                                <input type="text" id="voyageDetailsVoyageNo"
                                       class="ui-inputgroup"
                                       pInputText
                                       [(ngModel)]="forwardingFile.masterBill.voyageDetails.voyageNo">
                                <label>Voyage No.</label>
                              </span>
                            </div>
                          </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete
                                  [(ngModel)]="forwardingFile.masterBill.voyageDetails.customsRadioCallSign"
                                  field="vesselName"
                                  [suggestions]="filteredCustomsRadioCallSigns"
                                  (completeMethod)="searchRadioCallSigns($event, 'vesselName')"
                                  [autoHighlight]="true" minLength="2"
                                  required=true>
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.vesselName}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label>Vessel Name</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete
                                  [(ngModel)]="forwardingFile.masterBill.voyageDetails.customsRadioCallSign"
                                  field="radioCallSign"
                                  [suggestions]="filteredCustomsRadioCallSigns"
                                  (completeMethod)="searchRadioCallSigns($event, 'radioCallSign')"
                                  [autoHighlight]="true" minLength="2"
                                  required=true>
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.radioCallSign}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label>Radio Call Sign</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </span>
                          </div>
                        </ng-container>
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask id="departureDate"
                                               mask="99/99/9999"
                                               slotChar="dd/mm/yyyy"
                                               [(ngModel)]="forwardingFile.masterBill.departureDate">
                                  </p-inputMask>
                                  <label
                                    for="departureDate">{{ isTransportMethodCodeSameAs('air') ? 'Departure Date' : 'E.T.S'}}</label>
                                </span>
                              </span>
                               <div *ngIf="!forwardingFile?.masterBill?.departureDate"
                                    class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                            </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask id="arrivalDate"
                                               mask="99/99/9999"
                                               slotChar="dd/mm/yyyy"
                                               [(ngModel)]="forwardingFile.masterBill.arrivalDate">
                                  </p-inputMask>
                                  <label
                                    for="arrivalDate">{{ isTransportMethodCodeSameAs('air') ? 'Arrival Date' : 'E.T.A'}}</label>
                                </span>
                              </span>
                               <div *ngIf="!forwardingFile?.masterBill?.arrivalDate"
                                    class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                            </span>
                        </div>
                        <div *ngIf="isTransportMethodCodeSameAs('air')" class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask id="unpackDate"
                                               mask="99/99/9999"
                                               slotChar="dd/mm/yyyy"
                                               [(ngModel)]="forwardingFile.masterBill.unpackDate">
                                  </p-inputMask>
                                  <label for="unpackDate">Unpack Date</label>
                                </span>
                              </span>
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel [header]="isTransportMethodCodeSameAs('air') ? 'Airport Information':'Port Information'">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-md-12 ui-g-12"></div>
                    <div class="ui-g-12 ui-md-12">
                      <digi-port-details [portLabel]="isTransportMethodCodeSameAs('air') ? 'Departure Airport' : 'Departure Port'"
                                         [transportDetails]="forwardingFile?.masterBill?.departurePort"
                                         [transportMethodCode]="forwardingFile.transportMethodCode"
                                         [isRequired]="true"
                      ></digi-port-details>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText id="countryName"
                                         [ngModel]="forwardingFile?.masterBill?.departurePort?.countryName" disabled>
                                  <label>Departure Country</label>
                                </span>
                              </div>
                            </span>
                      </div>
                    </div>
                    <ng-container *ngIf="isTransportMethodCodeSameAs('sea')" class="ui-g-12 ui-md-12">
                      <div class="ui-g-12 ui-md-12">
                        <digi-port-details portLabel="Doc's Issued At Port"
                                           [transportDetails]="forwardingFile?.masterBill?.docsIssuedAtPort"
                                           [transportMethodCode]="'SEA'"
                                           [isRequired]="true"
                        ></digi-port-details>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText id="docsIssuedAtPortName"
                                         [ngModel]="forwardingFile?.masterBill?.docsIssuedAtPort?.countryName" disabled>
                                  <label>Doc's Issued At Country</label>
                                </span>
                              </div>
                            </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <digi-port-details portLabel='Discharge Port'
                                           [transportDetails]="forwardingFile?.masterBill?.dischargePort"
                                           [transportMethodCode]="'SEA'"
                                           [isRequired]="true"
                        ></digi-port-details>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText id="dischargePortCountryName"
                                         [ngModel]="forwardingFile?.masterBill?.dischargePort?.countryName" disabled>
                                  <label>Discharge Country</label>
                                </span>
                              </div>
                            </span>
                        </div>
                      </div>
                    </ng-container>
                    <div class="ui-g-12 ui-md-12">
                      <digi-port-details [portLabel]="isTransportMethodCodeSameAs('air') ? 'Destination Airport':'Destination Port'"
                                         [transportDetails]="forwardingFile?.masterBill?.destinationPort"
                                         [transportMethodCode]="forwardingFile.transportMethodCode"
                                         [isRequired]="true"
                      ></digi-port-details>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText id="destinationPortCountryName"
                                         [ngModel]="forwardingFile?.masterBill?.destinationPort?.countryName" disabled>
                                  <label>Destination Country</label>
                                </span>
                              </div>
                            </span>
                      </div>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete [(ngModel)]="forwardingFile.masterBill.depot" id="unpackDepot"
                                                field="label"
                                                [suggestions]="filteredDepotTerminals"
                                                (completeMethod)="searchDepotTerminal($event, 'D')"
                                                [autoHighlight]="true"
                                                minLength="1"
                                                [dropdown]="true">
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.label}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label>Unpack Depot</label>
                              </span>
                            </div>
                          </span>
                      </div>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete [(ngModel)]="forwardingFile.masterBill.terminal" field="label" id="dischargeTerminal"
                                                [suggestions]="filteredDepotTerminals"
                                                (completeMethod)="searchDepotTerminal($event, 'T')"
                                                [autoHighlight]="true" minLength="1"
                                                [dropdown]="true"
                                                [forceSelection]="true">
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.label}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label>Discharge Terminal</label>
                              </span>
                            </div>
                          </span>
                      </div>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
                          <div class="ui-inputgroup">
                            <span class="ui-float-label">
                            <p-autoComplete id="callPurpose"
                                            [(ngModel)]="forwardingFile.masterBill.callPurpose"
                                            [suggestions]="callPurposes"
                                            [dropdown]="true"
                                            (completeMethod)="callPurposes = ['Loading', 'Unloading']"
                            ></p-autoComplete>
                               <label for="callPurpose">Call Purpose</label>
                                </span>
                          </div>
                           <div *ngIf="!forwardingFile?.masterBill?.callPurpose"
                                class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
              <br>
              <p-panel header="Customs Office">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <digi-customs-office [customOffice]="forwardingFile.customOffice"
                                               [isRequired]=true
                                               (onSelectedCustomOffice)="updateCustomsOffice($event)"></digi-customs-office>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Agent Information">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g-12 ui-g-nopad">
                        <digi-business-entity-search [businessEntity]="forwardingFile.masterBill.overseasAgent"
                                                     [showAddress]="false"
                                                     [roleName]="'Overseas Agent'"
                                                     [roleID]="5"
                                                     [isNomination]="false"
                                                     (selectedBusinessEntity)="selectOverseasAgent($event)"
                        ></digi-business-entity-search>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <span class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="releaseAgent" [(ngModel)]="forwardingFile.masterBill.releaseAgent"
                                                field="name"
                                                [suggestions]="filteredLocalForwarders"
                                                (completeMethod)="searchLocalForwarder($event)"
                                                [autoHighlight]="true"
                                                minLength="3">
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.name}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label for="releaseAgent">Release Agent</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <span class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="localForwarder"
                                                [(ngModel)]="forwardingFile.masterBill.localForwarder"
                                                field="name"
                                                [suggestions]="filteredLocalForwarders"
                                                (completeMethod)="searchLocalForwarder($event)"
                                                [autoHighlight]="true"
                                                minLength="3">
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.name}}</div>
                                  </ng-template>
                                </p-autoComplete>
                                <label for="localForwarder">Forwarding Agent</label>
                              </span>
                              <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                            </span>
                             <div *ngIf="!forwardingFile?.masterBill?.localForwarder?.cargoCarrierCode"
                                  class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-g-nopad">
                        <digi-business-entity-search [businessEntity]="forwardingFile.masterBill.coLoader"
                                                     [showAddress]="false"
                                                     [roleName]="'Co-Loader'"
                                                     [roleID]="4"
                                                     [isNomination]="false"
                                                     (selectedBusinessEntity)="selectCoLoader($event)"
                        ></digi-business-entity-search>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
              <br>
              <p-panel header="Freight">
                <digi-hawb-freight-section [freight]="forwardingFile.masterBill.freight"
                                           [required]="false"></digi-hawb-freight-section>
              </p-panel>
            </div>
          </div>
          <div class="ui-g">
            <div class="ui-g-4">
              <p-panel header="Weights & Measures">
                <div class="ui-g ui-fluid">
                  <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" id="numberOfHawbs"
                             pInputText
                             [(ngModel)]="forwardingFile.masterBill.measures.numberOfHawbs">
                      <label>No. of HAWBs</label>
                    </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" id="numberOfPackages"
                             pInputText
                             [(ngModel)]="forwardingFile.masterBill.measures.numberOfPackages">
                      <label>No. of Packages</label>
                       <div *ngIf="!forwardingFile?.masterBill?.measures?.numberOfPackages"
                            class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                    </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" id="grossWeight" pInputText [(ngModel)]="forwardingFile.masterBill.measures.grossWeight">
                      <label>Gross Weight (kg)</label>
                      <div *ngIf="!forwardingFile?.masterBill?.measures?.grossWeight"
                           class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                    </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                      <span class="md-inputfield">
                        <input type="text" id="chargeableWeight" pInputText
                               [(ngModel)]="forwardingFile.masterBill.measures.chargeableWeight">
                        <label>Chargeable Weight (kg)</label>
                        <div *ngIf="!forwardingFile?.masterBill?.measures?.chargeableWeight"
                             class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                      </span>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="References">
                <div class="ui-fluid">
                  <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" id="businessReference"
                             pInputText
                             [(ngModel)]="forwardingFile.reference.businessReference">
                      <label>{{isTransportMethodCodeSameAs('air') ? 'MAWB' : 'MBOL'}} References</label>
                    </span>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Storage Warehouse Detail">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="forwardingFile.warehouse"
                                                   [showAddress]="true"
                                                   [roleName]="'Warehouse'"
                                                   [roleID]="13"
                                                   [isNomination]="false"
                                                   [requiresValidation]="false"
                                                   (selectedBusinessEntity)="selectWarehouse($event)"
                      ></digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <ng-container>
    <p-tabPanel [header]="isTransportMethodCodeSameAs('air') ? 'HAWB' : 'House BOL'">
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <digi-forwarding-file-header-section (add)="addNewHawb()">
          </digi-forwarding-file-header-section>
        </div>
        <div *ngIf="showProgressBar" class="ui-g-12">
          <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
        </div>
        <div class="ui-g-12">
          <p-messages [(value)]="validationMessagesForHawbNo"></p-messages>
        </div>
        <div class="ui-g-1">
          <button pButton label="Search" icon="pi pi-search" (click)="searchHousebill()"></button>
        </div>
        <div class="ui-g-3">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" pInputText
                     [(ngModel)]="hawbFilter">
              <label>Search HAWB</label>
            </span>
            <button pButton icon="fa fa-times" label="Clear" (click)="clearHawbSearch()"></button>
          </div>
        </div>
        <div class="ui-g-3">
          <button *ngIf="showTrackingButton && config.enableTracking" pButton label="Generate HAWB Report" icon="fa fa-file" (click)="generateReport()"></button>
        </div>
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-fluid">
            <div class="ui-g">
              <div class="ui-g-8">
                <p-table [columns]="cols" [value]="houseBillSelection" selectionMode="single"
                         columnResizeMode="expand"
                         (onRowSelect)="selectHouseBill($event.data)"
                         [(selection)]="selectedHouseBill" [paginator]="true" [rows]="5" [first]="tableFirst">
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns">{{col.header}}</th>
                      <th style="width: 4%">
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-houseBill let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="houseBill" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
                      <td>{{houseBill.hawbNo}}</td>
                      <td>{{houseBill.importer?.name}}</td>
                      <td>{{houseBill.clientRefNo}}</td>
                      <td>{{houseBill.freight?.amount}}</td>
                      <td *ngIf="showTrackingButton && config.enableTracking">
                        <button pButton type="button" label="Tracking" icon="fa fa-plus"
                                (click)="track(houseBill, true)"
                        ></button>
                      </td>
                      <td>
                        <button pButton type="button" pCancelEditableRow icon="fa fa-times" class="danger"
                                (click)="onRowDelete(rowIndex)" pTooltip="Delete"></button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
              <div class="ui-g-4">
                <div class="ui-g-12"></div>
                <p-panel header="Totals">
                  <div class="ui-g ui-fluid">
                    <div class="ui-g-12"></div>
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield ui-md-4">MAWB</span>
                        <span class="md-inputfield ui-md-1">
          <input type="text"
                 value="{{forwardingFile.masterBill?.measures?.numberOfHawbs}}"
                 pInputText
                 readonly>
          <label>HBs</label>
        </span>
                        <span class="md-inputfield ui-md-1">
          <input type="text" value="{{forwardingFile.masterBill?.measures?.numberOfPackages}}"
                 pInputText
                 readonly>
          <label>Packs</label>
        </span>
                        <span class="md-inputfield ui-md-3">
          <input type="text" value="{{forwardingFile.masterBill?.measures?.grossWeight}}"
                 pInputText
                 readonly>
          <label>Gross Weight</label>
        </span>
                        <span class="md-inputfield ui-md-3">
          <input type="text" value="{{forwardingFile.masterBill?.measures?.chargeableWeight}}"
                 pInputText
                 readonly>
          <label>Chargeable Weight</label>
        </span>
                      </div>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield ui-md-4">Captured</span>
                        <span class="md-inputfield ui-md-1">
          <input type="text"
                 value="{{forwardingFile.masterBill?.runningTotals?.capturedNoOfHouseBills}}"
                 pInputText
                 readonly>
        </span>
                        <span class="md-inputfield ui-md-1">
          <input type="text" value="{{forwardingFile.masterBill?.runningTotals?.capturedNoOfPacks}}"
                 pInputText
                 readonly>
        </span>
                        <span class="md-inputfield ui-md-3">
          <input type="text" value="{{forwardingFile.masterBill?.runningTotals?.capturedWeight}}"
                 pInputText
                 readonly>
        </span>
                        <span class="md-inputfield ui-md-3">
          <input type="text" value="{{forwardingFile.masterBill?.runningTotals?.capturedChargeableWeight}}"
                 pInputText
                 readonly>
        </span>
                      </div>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield ui-md-4">Balance</span>
                        <span class="md-inputfield ui-md-1">
          <input type="text"
                 value="{{forwardingFile.masterBill?.runningTotals?.remainingNoOfHouseBills}}"
                 pInputText
                 readonly>
        </span>
                        <span class="md-inputfield ui-md-1">
          <input type="text" value="{{forwardingFile.masterBill?.runningTotals?.remainingNoOfPacks}}"
                 pInputText
                 readonly>
        </span>
                        <span class="md-inputfield ui-md-3">
          <input type="text"
                 value="{{forwardingFile.masterBill?.runningTotals?.remainingWeight}}"
                 pInputText
                 readonly>
        </span>
                        <span class="md-inputfield ui-md-3">
          <input type="text" value="{{forwardingFile.masterBill?.runningTotals?.remainingChargeableWeight}}"
                 pInputText
                 readonly>
        </span>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="ui-g-12 ui-g-nopad">
                <div class="ui-g">
                  <div class="ui-g-4">
                    <p-panel header="HAWB Details">
                      <div class="ui-fluid">
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <div class="ui-g form-group">
                              <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" pInputText
                                             [(ngModel)]="selectedHouseBill.hawbNo"
                                             (focusout)="validateHawbNo()"
                                             id="selectedHouseBillHawbNo"
                                      >
                                      <label>HAWB No.</label>
                                    </span>
                                  </div>
                                  <div *ngIf="!selectedHouseBill?.hawbNo"
                                       class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                                </span>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <span class="ui-float-label">
                                    <span class="md-inputfield">
                                      <p-inputMask id="hawbDate"
                                                   mask="99/99/9999"
                                                   slotChar="dd/mm/yyyy"
                                                   [(ngModel)]="selectedHouseBill.hawbDate">
                                      </p-inputMask>
                                      <label for="hawbDate">HAWB Date</label>
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="md-inputfield">
                                      <input type="text" pInputText
                                             id="selectedHouseBillClientRefNo"
                                             [(ngModel)]="selectedHouseBill.clientRefNo">
                                      <label>Client Ref. No.</label>
                                    </span>
                                  </div>
                                </span>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                                <digi-port-details [portLabel]="isTransportMethodCodeSameAs('air') ? 'Final Destination Airport' : 'Final Destination Port'"
                                                   [transportDetails]="selectedHouseBill.destinationPort"
                                                   [transportMethodCode]="forwardingFile.transportMethodCode"
                                                   [isRequired]="true"
                                ></digi-port-details>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                                <digi-port-details [portLabel]="isTransportMethodCodeSameAs('air') ? 'Loading Airport' : 'Loading Port'"
                                                   [transportDetails]="selectedHouseBill.departurePort"
                                                   [transportMethodCode]="forwardingFile.transportMethodCode"
                                                   [isRequired]="true"
                                ></digi-port-details>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                  <div class="ui-g-4">
                    <p-panel header="Weights & Measures">
                      <div class="ui-g ui-fluid">
                        <div class="ui-g form-group">
                          <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text"
                             pInputText
                             id="measuresNumberOfPacks"
                             [(ngModel)]="selectedHouseBill.measures.numberOfPackages"
                             (ngModelChange)="updateRunningTotals()">
                      <label>No. of Packages</label>
                      <div *ngIf="!selectedHouseBill?.measures?.numberOfPackages"
                           class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                    </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text"
                             pInputText
                             id="measuresVolume"
                             [(ngModel)]="selectedHouseBill.measures.volume">
                      <label>Volume</label>
                    </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedHouseBill.measures.grossWeight"
                             id="measuresGrossWeight"
                             (ngModelChange)="updateRunningTotals()">
                      <label>Gross Weight (kg)</label>
                      <div *ngIf="!selectedHouseBill?.measures?.grossWeight"
                           class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                    </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                      <span class="md-inputfield">
                        <input type="text" pInputText
                               id="measuresChargeableWeight"
                               [(ngModel)]="selectedHouseBill.measures.chargeableWeight"
                               (ngModelChange)="updateRunningTotals()">
                        <label>Chargeable Weight (kg)</label>
                        <div *ngIf="!selectedHouseBill?.measures?.chargeableWeight"
                             class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                      </span>
                          </div>
                          <div *ngIf="isTransportMethodCodeSameAs('sea')" class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <input type="text" id="numberOfContainers"
                                   pInputText
                                   [(ngModel)]="selectedHouseBill.measures.noOfContainers">
                            <label>No. of Containers</label>
                             <div *ngIf="!selectedHouseBill?.measures?.noOfContainers"
                                  class="ui-message ui-messages-error ui-corner-all">
                                    This field is required
                                  </div>
                          </span>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                  <div class="ui-g-4">
                    <p-panel header="Freight">
                      <digi-hawb-freight-section [freight]="selectedHouseBill.freight"
                                                 [required]="false"></digi-hawb-freight-section>
                    </p-panel>
                  </div>
                </div>
                <div class="ui-g">
                  <div class="ui-g-4">
                    <p-panel header="Importer Details">
                      <div class="ui-fluid">
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <digi-business-entity-search [businessEntity]="selectedHouseBill.importer"
                                                         [showAddress]="true"
                                                         [roleName]="'Importer'"
                                                         [roleID]="6"
                                                         [canAdd]="true"
                                                         [isNomination]="false"
                                                         [requiresValidation]="true"
                                                         (selectedBusinessEntity)="selectImporter($event)"
                            ></digi-business-entity-search>
                          </div>
                        </div>
                      </div>
                      <div class="ui-g form-group">
                      <div class="ui-md-12">
                          <div class="ui-inputgroup">
                            <button pButton
                                    type="button"
                                    icon="fa fa-file"
                                    class="ui-button-secondary"
                                    type="button"
                                    label="Edit Importer"
                                    (click)="openImporterModal()"></button>
                          </div>
                        </div>
                      </div>
                      <digi-forwarding-importer-modal [display]="displayImporterModal"
                                           (created)="onImporterCreated($event)"></digi-forwarding-importer-modal>
                    </p-panel>
                  </div>
                  <div class="ui-g-4">
                    <p-panel header="Clearing Agent">
                      <div class="ui-fluid">
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <div class="ui-g form-group">
                              <div class="ui-g-12">
                                <digi-business-entity-search [businessEntity]="selectedHouseBill.localAgent"
                                                             [showAddress]="true"
                                                             [roleName]="'Clearing Agent'"
                                                             [roleID]="4"
                                                             [canAdd]="true"
                                                             [isNomination]="false"
                                                             (selectedBusinessEntity)="selectLocalAgent($event)"
                                ></digi-business-entity-search>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                  <div class="ui-g-4">
                    <p-panel header="Supplier Details">
                      <div class="ui-g ui-fluid">
                        <div class="ui-g form-group">
                          <div class="ui-g-12">
                            <digi-business-entity-search [businessEntity]="selectedHouseBill.supplier"
                                                         [showAddress]="true"
                                                         [roleName]="'Supplier'"
                                                         [roleID]="21"
                                                         [canAdd]="true"
                                                         [isNomination]="false"
                                                         [requiresValidation]="true"
                                                         (selectedBusinessEntity)="selectSupplier($event)"
                            ></digi-business-entity-search>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                </div>
                <div class="ui-g">
                  <div class="ui-g-4">
                    <p-panel header="Debtor Details">
                      <div class="ui-fluid">
                        <div class="ui-g form-group">
                          <div class="ui-g-12">
                            <digi-business-entity-search [businessEntity]="selectedHouseBill.debtor"
                                                         [showAddress]="true"
                                                         [roleName]="'Debtor'"
                                                         [roleID]="1"
                                                         [isNomination]="false"
                                                         (selectedBusinessEntity)="selectDebtor($event)"
                            ></digi-business-entity-search>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                  <div class="ui-g-4">
                    <p-panel header="Overseas Agent">
                      <div class="ui-fluid">
                        <div class="ui-g form-group">
                          <div class="ui-g-12">
                            <digi-business-entity-search [businessEntity]="selectedHouseBill.overseasAgent"
                                                         [showAddress]="true"
                                                         [roleName]="'Overseas Agent'"
                                                         [roleID]="5"
                                                         [isNomination]="false"
                                                         (selectedBusinessEntity)="selectHouseOverseas($event)"
                            ></digi-business-entity-search>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                  <div class="ui-g-4"  *ngIf="displayCustomsValuePanel && selectedHouseBill.customsValue">
                    <p-panel header="Customs Value">
                      <div class="ui-g ui-fluid">
                        <div class="ui-g form-group">
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                             <span class="ui-float-label">
                                <p-autoComplete id="currency" [(ngModel)]="selectedHouseBill.customsValue.currency" field="label" [dropdown]="true"
                                [suggestions]="filteredCurrencies"
                                (completeMethod)="search($event)"
                                [autoHighlight]="true" (onSelect)="select($event)"
                                minLength="1"
                                [forceSelection]="true"
                                [readonly]="readonly" required=true>
                                 <ng-template let-option pTemplate="item">
                               <div>{{option.label}}</div>
                                 </ng-template>
                               </p-autoComplete>
                             <label for="currency">Currency</label>
                              </span>
                             </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                          <span class="md-inputfield">
                           <input type="text" pInputText
                                  [(ngModel)]="selectedHouseBill.customsValue.amount">
                            <label>Amount</label>
                           </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                          <span class="md-inputfield">
                           <input type="text" pInputText
                                  [(ngModel)]="selectedHouseBill.customsValue.localAmount">
                            <label>Local Amount</label>
                           </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                          <span class="md-inputfield">
                           <input type="text" pInputText
                                  [(ngModel)]="selectedHouseBill.customsValue.currency.exchangeRate">
                            <label>Exchange Rate</label>
                           </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                  <div class="ui-g-4" *ngIf="displaySupplierInvoicePanel && selectedHouseBill.supplier">
                      <p-panel header="Invoice Details">
                      <div class="ui-g ui-fluid">
                        <div class="ui-g form-group">
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                          <span class="md-inputfield">
                           <input type="text" pInputText
                                  [(ngModel)]="selectedHouseBill.supplier.invoiceNumber">
                            <label>Invoice Number</label>
                           </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                          <span class="md-inputfield">
                           <input type="text" pInputText
                                  [(ngModel)]="selectedHouseBill.supplier.invoiceDate">
                            <label>Invoice Date</label>
                           </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>


               <div class="ui-g-4" *ngIf="displayTariffCodesPanel && selectedHouseBill.tariffCodes">
                 <p-panel header="Tariff Codes">
                  <div class="ui-g ui-fluid">
                    <div class="ui-g-12 ui-md-12">
                      <div *ngFor="let tariffCode of selectedHouseBill.tariffCodes; let i = index" style="display: flex; align-items: center; margin-bottom: 10px;">
                        <input
                                type="text"
                                [(ngModel)]="tariffCode.code"
                                [attr.placeholder]="'Tariff Code ' + (i + 1)"
                                class="ui-inputtext ui-corner-all"
                                style="flex: 1; margin-right: 10px;">
                        <button pButton type="button" icon="pi pi-trash" class="ui-button-danger" (click)="removeTariffCode(i)" style="flex: none;"></button>
                      </div>
                      <div>
                        <button pButton type="button" icon="fa fa-plus" label="Add Tariff Code"(click)="addTariffCode()" style="margin-bottom: 20px;"></button>
                      </div>
                      <div>
                        <button pButton type="button" icon="fa fa-save" label="Save Tariff Code"(click)="saveForwardingFileAfterUpdatingTariffCodes()"></button>
                      </div>
                    </div>
                    <div class="ui-g-6 ui-md-6">
                       <div class="ui-inputgroup">
                          <span class="ui-float-label">
                          <p-autoComplete id= "tariffCodeSearch" [(ngModel)]="searchedTariff" field="code"
                                          [suggestions]="filteredTariffCodes"
                                          (completeMethod)="searchTariffCode($event)"
                                          [autoHighlight]="true"
                                          (onSelect)="selectTariffCode($event)"
                                          minLength="3"
                                          placeholder="Tariff Code Inquiry">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.code}}: {{option.tariffSDesc}}</div>
                        </ng-template>
                      </p-autoComplete>
                    </span>
                  <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                 </div>
                </div>
               </div>
              </p-panel>
             </div>

                  <div class="ui-g-4">
                    <p-panel header="Duties and Taxes" *ngIf="displayDutiesAndTaxesPanel && selectedHouseBill.tariffCodes">
                      <div class="ui-g ui-fluid">
                        <div class="ui-g form-group">
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                            <input type="text" pInputText [value]="totalCustomsDuty" readonly>
                               <label>Total Customs Duty</label>
                              </span>
                            </div>
                          </div>

                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                            <span class="md-inputfield">
                             <input type="text" pInputText [value]="totalATV" readonly>
                              <label>Total ATV</label>
                            </span>
                            </div>
                          </div>

                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                             <span class="md-inputfield">
                             <input type="text" pInputText [value]="totalVAT" readonly>
                               <label>Total VAT</label>
                             </span>
                            </div>
                          </div>

                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                              <span class="md-inputfield">
                               <input type="text" pInputText [value]="totalPayable" readonly>
                              <label>Total Payable</label>
                               </span>
                            </div>
                          </div>

                        </div>
                      </div>
                    </p-panel>
                  </div>

                  <div *ngIf="isTransportMethodCodeSameAs('sea')" class="ui-g-4">
                    <p-panel header="Notify Party to print on B/L">
                      <div class="ui-fluid">
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <div class="ui-g form-group">
                              <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           id="notifyPartyName"
                           [(ngModel)]="selectedHouseBill.notifyPartyName">
                   <label>Name</label>
                 </span>
              </div>
          </span>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           id="notifyPartyAddressLine1"
                           [(ngModel)]="selectedHouseBill.notifyPartyAddress.line1">
                   <label>Address</label>
                 </span>
              </div>
          </span>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           id="notifyPartyAddressLine2"
                           [(ngModel)]="selectedHouseBill.notifyPartyAddress.line2">
                 </span>
              </div>
          </span>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           id="notifyPartyAddressLine3"
                           [(ngModel)]="selectedHouseBill.notifyPartyAddress.line3">
                 </span>
              </div>
          </span>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           id="notifyPartyAddressLine4"
                           [(ngModel)]="selectedHouseBill.notifyPartyAddress.line4">
                 </span>
              </div>
          </span>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
              <div class="ui-inputgroup">
                 <span class="md-inputfield">
                    <input type="text"
                           class="ui-inputgroup"
                           pInputText
                           id="notifyPartyAddressZipCode"
                           [(ngModel)]="selectedHouseBill.notifyPartyAddress.zipCode">
                   <label>Zip Code</label>
                 </span>
              </div>
          </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                  <div *ngIf="isTransportMethodCodeSameAs('sea')" class="ui-g-4">
                    <p-panel header="Trans-Shipment Details">
                      <div class="ui-fluid">
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <div class="ui-g form-group">
                              <div  class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                    <div class="ui-inputgroup">
                                       <span class="md-inputfield">
                                          <input type="text"
                                                 class="ui-inputgroup"
                                                 pInputText
                                                 id="feederVessel"
                                                 [ngModel]="selectedHouseBill?.transShipmentDetails?.feederVessel"
                                                 (ngModelChange)="selectedHouseBill?.transShipmentDetails?.feederVessel && selectedHouseBill.transShipmentDetails ? selectedHouseBill.transShipmentDetails.feederVessel=$event : null"
                                                 required>
                                         <label>Feeder Vessel</label>
                                       </span>
                                    </div>
                                </span>
                              </div>
                              <div  class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                    <div class="ui-inputgroup">
                                       <span class="md-inputfield">
                                          <input type="text"
                                                 class="ui-inputgroup"
                                                 pInputText
                                                 id="feederVoyageNo"
                                                 [ngModel]="selectedHouseBill?.transShipmentDetails?.feederVoyageNo"
                                                 (ngModelChange)="selectedHouseBill?.transShipmentDetails?.feederVoyageNo && selectedHouseBill.transShipmentDetails ? selectedHouseBill.transShipmentDetails.feederVoyageNo=$event : null"
                                                 required>
                                         <label>Feeder Voyage No.</label>
                                       </span>
                                    </div>
                                </span>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                                <digi-port-details portLabel="Transfer Port"
                                                   [transportDetails]="selectedHouseBill?.transShipmentDetails?.transferPort"
                                                   [transportMethodCode]="forwardingFile.transportMethodCode"
                                                   [isRequired]="false"
                                ></digi-port-details>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                                <span class="md-inputfield">
                                  <span class="ui-float-label">
                                      <p-inputMask id="transShipmentDepartureDate" mask="99/99/9999"
                                                   slotChar="dd/mm/yyyy"
                                                   [ngModel]="selectedHouseBill?.transShipmentDetails?.departureDate"
                                                   (ngModelChange)="selectedHouseBill?.transShipmentDetails?.departureDate && selectedHouseBill.transShipmentDetails ? selectedHouseBill.transShipmentDetails.departureDate=$event : null"
                                      >
                                      </p-inputMask>
                                      <label for="transShipmentDepartureDate">ETD</label>
                                  </span>
                                </span>
                              </div>
                              <div class="ui-g-12 ui-md-12">
                              <span class="md-inputfield">
                                <span class="ui-float-label">
                                    <p-inputMask id="transShipmentArrivalDate" mask="99/99/9999"
                                                 slotChar="dd/mm/yyyy"
                                                 [ngModel]="selectedHouseBill?.transShipmentDetails?.arrivalDate"
                                                 (ngModelChange)="selectedHouseBill?.transShipmentDetails?.arrivalDate && selectedHouseBill.transShipmentDetails ? selectedHouseBill.transShipmentDetails.arrivalDate=$event : null"
                                    >
                                    </p-inputMask>
                                    <label for="transShipmentArrivalDate">ETA</label>
                                </span>
                              </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </ng-container>
  <ng-container
    *ngIf="isTransportMethodCodeSameAs('sea')">
    <p-tabPanel header="Containers">
      <ng-template pTemplate="content">
        <div class="ui-g-12 ui-g-nopad">
          <digi-containers-section [type]="'forwarding'">
            <digi-forwarding-file-header-section (add)="addContainer()">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon">HBOL No</span>
                    <input type="text" pInputText value="selectedHouseBill? selectedHouseBill.hawbNo : null" readonly>
                  </div>
                </div>
              </div>
            </digi-forwarding-file-header-section>
          </digi-containers-section>
        </div>
      </ng-template>
    </p-tabPanel>
  </ng-container>
  <ng-container>
    <p-tabPanel header="Marks & Numbers">
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <digi-forwarding-file-header-section>
          </digi-forwarding-file-header-section>
        </div>
        <div class="ui-g-12 ui-g-nopad">
          <digi-manifest-marks-and-numbers [marksAndNumbers]="selectedHouseBill.marksNumbersAndEndorsement"
                                           (updateMarksAndNumbers)="updateMarksAndNumbers($event)"></digi-manifest-marks-and-numbers>
        </div>
      </div>
    </p-tabPanel>
  </ng-container>
  <ng-container>
    <p-tabPanel header="Packages">
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <digi-forwarding-file-header-section (add)="addNewPackage()">
          </digi-forwarding-file-header-section>
        </div>
        <div class="ui-g-12">
          <p-table [columns]="[{}]" [value]="selectedHouseBill.packages" [(selection)]="selectedPackage"
                   [paginator]="true" [rows]="4"
                   selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th>Line</th>
                <th>Description</th>
                <th>LRN</th>
                <th>Quantity</th>
                <th>CQDesc</th>
                <th>Weight</th>
                <th>Volume</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-rowIndex="rowIndex">
              <tr [pSelectableRow]="package">
                <td class="ui-resizable-column">
                  {{rowIndex + 1}}
                </td>
                <td class="ui-resizable-column">
                  <label>{{package.description}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{package.referenceNo}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{package.noOfPackages}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{package.packageType?.description}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{package.weight}}</label>
                </td>
                <td class="ui-resizable-column">
                  <label>{{package.volume}}</label>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g">
            <div class="ui-g-4">
              <p-panel header="Package Details">
                <div class="ui-fluid">
                  <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="packageDescription" [(ngModel)]="selectedPackage.description">
                      <label>Package Description</label>
                    </span>
                <div *ngIf="!selectedPackage?.description" class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="vinNumber" [(ngModel)]="selectedPackage.vinNumber">
                      <label>Vin Number</label>
                    </span>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="referenceNo" [(ngModel)]="selectedPackage.referenceNo">
                      <label>LRN/MRN</label>
                    </span>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="noOfPackages" [(ngModel)]="selectedPackage.noOfPackages">
                      <label>No. Of Packages</label>
                    </span>
                <div *ngIf="!selectedPackage?.noOfPackages" class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="ucrNumber" [(ngModel)]="selectedPackage.ucrNumber">
                      <label>UCR Number</label>
                    </span>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="packageWeight" [(ngModel)]="selectedPackage.weight">
                      <label>Package Weight</label>
                    </span>
                <div *ngIf="!selectedPackage?.weight" class="ui-message ui-messages-error ui-corner-all">
                              This field is required
                            </div>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="packageVolume" [(ngModel)]="selectedPackage.volume">
                      <label>Package Volume</label>
                    </span>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                      <span class="md-inputfield">
                        <div class="ui-inputgroup">
                  <span class="ui-float-label">
                    <p-autoComplete id="packType" [(ngModel)]="selectedPackage.packageType"
                                    field="label"
                                    [dropdown]="true"
                                    [suggestions]="filteredPackTypes"
                                    (completeMethod)="searchPackTypes($event)"
                                    (onSelect)="selectPackType($event)"
                                    [autoHighlight]="true" minLength="1"
                                    required="true">
                      <ng-template let-option pTemplate="item">
                        <div>{{option.code}} :{{option.description}}</div>
                      </ng-template>
                    </p-autoComplete>
                  <label for="packType">Package Type</label>
                </span>
                      </div>
                      <div *ngIf="!selectedPackage?.packageType?.code"
                           class="ui-message ui-messages-error ui-corner-all">
                        This field is required
                      </div>
                      </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                <div class="ui-inputgroup">
                  <span class="ui-float-label">
                      <p-autoComplete id="tariff" [(ngModel)]="selectedPackage.tariffCode" field="code"
                                      [suggestions]="filteredTariffCodes"
                                      (completeMethod)="searchTariffCode($event)"
                                      [autoHighlight]="true"
                                      (onSelect)="selectTariffCode($event)"
                                      minLength="3"
                                      required="true">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.code}}</div>
                        </ng-template>
                      </p-autoComplete>
                      <label for="tariff">Tariff Code</label>
                  </span>
                  <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                </div>
            </span>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Goods Description">
                <div class="ui-fluid">
                  <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="goodsDescriptionCode" [(ngModel)]="selectedPackage.goodsDescription.code">
                      <label>Code</label>
                    </span>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="goodsDescriptionUndgCode" [(ngModel)]="selectedPackage.goodsDescription.undgCode">
                      <label>UNDGCode</label>
                    </span>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="goodsDescriptionImoPageNo" [(ngModel)]="selectedPackage.goodsDescription.imoPageNo">
                      <label>IMO Page No.</label>
                    </span>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
                          <textarea pInputTextarea [(ngModel)]="selectedPackage.goodsDescription.description" [rows]="3"
                                    [cols]="50"
                                    placeholder="Description"
                                    id="goodsDescriptionDescription"
                                    style="resize: none">
                          </textarea>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="goodsDescriptionUnDangerousGoodsNo" [(ngModel)]="selectedPackage.goodsDescription.unDangerousGoodsNo">
                      <label>UN Dangerous Goods No.</label>
                    </span>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="goodsDescriptionHazardCodeVersionNo" [(ngModel)]="selectedPackage.goodsDescription.hazardCodeVersionNo">
                      <label>Hazard Code Version No.</label>
                    </span>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="goodsDescriptionUndgFlashPoint" [(ngModel)]="selectedPackage.goodsDescription.undgFlashPoint">
                      <label>UNDG Flash Point</label>
                    </span>
                </span>
                    </div>
                    <div class="ui-g-12 ui-md-12">
              <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText id="goodsDescriptionUndgFlashPointUnit" [(ngModel)]="selectedPackage.goodsDescription.undgFlashPointUnit">
                      <label>UNDG Flash Point Unit</label>
                    </span>
                </span>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </ng-container>
  <ng-container
    *ngIf="config.enableTracking && generatedFiles">
    <p-tabPanel header="Email Report">
      <ng-template pTemplate="content">
        <div class="ui-fluid">
          <div class="ui-g">
            <digi-customs-documents-email [fileType]="'forwarding'" [generatedReportFiles]="generatedFiles"></digi-customs-documents-email>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
  </ng-container>
  <ng-container>
    <p-tabPanel header="Digi Scan">
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <p-toolbar class="toolbar">
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="Save"
                      icon="fa fa-print" [disabled]="disableButton"
                      (click)="saveDigiForwardingFile()"></button>
            </div>
            <div class="ui-toolbar-group-right">
              <button pButton type="button" label="Print"
                      icon="fa fa-print" [disabled]="disableButton"
                      (click)="loadGeneratedFiles()"></button>
            </div>
          </p-toolbar>
          <p-dialog header="Generated Documents" [(visible)]="showFilesDialog" [modal]="true"
                    [responsive]="true" [width]="400"
                    [minWidth]="200" [minY]="70"
                    [maximizable]="true" [baseZIndex]="10000">
            <ng-template pTemplate="content" [ngIf]="generatedFiles">
              <li *ngFor="let generatedDocument of generatedFiles">
                <a class="uploaded-file-color"
                   [href]="generatedDocument._links.self.href" target="_blank">{{generatedDocument.fileName}}</a>
              </li>
            </ng-template>
            <p-footer>
              <button type="button" pButton icon="pi pi-close" (click)="showFilesDialog=false"
                      label="Close"
                      class="ui-button-secondary"></button>
            </p-footer>
          </p-dialog>
        </div>
        <div class="ui-g-3">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" pInputText
                     [(ngModel)]="selectedHouseBill.digiScan.scanHawb">
              <label>Scan HAWB</label>
            </span>
          </div>
        </div>
        <div class="ui-g-3">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" pInputText
                     [(ngModel)]="selectedHouseBill.digiScan.specialInstruction">
              <label>Special Instruction</label>
            </span>
          </div>
        </div>
        <div class="ui-g-3">
          <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" pInputText
                     [(ngModel)]="selectedHouseBill.digiScan.customerReference">
              <label>Customer Reference</label>
            </span>
          </div>
        </div>
        <div class="ui-g-12 ui-g-nopad">
                <div class="ui-g">
                  <div class="ui-g-6">
                    <p-panel header="Importer Details">
                      <div class="ui-fluid">
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <digi-business-entity-search [businessEntity]="selectedHouseBill.digiScan.importer"
                                                         [showAddress]="true"
                                                         [roleName]="'Importer'"
                                                         [roleID]="6"
                                                         [canAdd]="false"
                                                         [isNomination]="false"
                                                         [requiresValidation]="true"
                                                         (selectedBusinessEntity)="selectDigiScanImporter($event)"
                            ></digi-business-entity-search>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                  <div class="ui-g-6">
                    <p-panel header="Supplier Details">
                      <div class="ui-g ui-fluid">
                        <div class="ui-g form-group">
                          <div class="ui-g-12">
                            <digi-business-entity-search [businessEntity]="selectedHouseBill.digiScan.supplier"
                                                         [showAddress]="true"
                                                         [roleName]="'Supplier'"
                                                         [roleID]="21"
                                                         [canAdd]="false"
                                                         [isNomination]="false"
                                                         [requiresValidation]="true"
                                                         (selectedBusinessEntity)="selectDigiScanSupplier($event)"
                            ></digi-business-entity-search>
                          </div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                </div>
                <div class="ui-g">
                </div>
              </div>
            </div>
      <button pButton type="button" icon="fa fa-plus"
              (click)="addNewLine()" label="Add Line"></button>
      <p-table [value]="rows" editMode="row" #templateRef>
        <ng-template pTemplate="header">
          <tr>
            <th style="width:10%">No Items</th>
            <th style="width:40%">Description of Goods</th>
            <th style="width:10%">Length</th>
            <th style="width:10%">Width</th>
            <th style="width:10%">Height</th>
            <th style="width:10%">Total Mass</th>
            <th style="width:10%">Vol Mass</th>
            <th style="width:4em"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
          <tr #row>
            <td>
              <input [(ngModel)]="selectedHouseBill.digiScan.noOfItems" style="width: 100%; padding: 0; height: 30px;  text-align: center;" class="ui-fluid" type="text" />
            </td>
            <td>
              <input [(ngModel)]="selectedHouseBill.digiScan.descriptionOfGoods" style="width: 100%; padding: 0; height: 30px; text-align: center;" class="ui-fluid" type="text" />
            </td>
            <td>
              <input [(ngModel)]="selectedHouseBill.digiScan.length" (ngModelChange)="selectedHouseBill.digiScan.volMass = calculateVMass(selectedHouseBill.digiScan)" style="width: 100%; padding: 0; height: 30px; text-align: center;" class="ui-fluid" type="text" />
            </td>
            <td>
              <input [(ngModel)]="selectedHouseBill.digiScan.width" (ngModelChange)="selectedHouseBill.digiScan.volMass = calculateVMass(selectedHouseBill.digiScan)" style="width: 100%; padding: 0; height: 30px; text-align: center;" class="ui-fluid" type="text" />
            </td>
            <td>
              <input [(ngModel)]="selectedHouseBill.digiScan.height" (ngModelChange)="selectedHouseBill.digiScan.volMass = calculateVMass(selectedHouseBill.digiScan)" style="width: 100%; padding: 0; height: 30px;  text-align: center;" class="ui-fluid" type="text" />
            </td>
            <td>
              <input [(ngModel)]="selectedHouseBill.digiScan.totalMass" style="width: 100%; padding: 0; height: 30px;  text-align: center;" class="ui-fluid" type="text" />
            </td>
            <td>
              <input [(ngModel)]="selectedHouseBill.digiScan.volMass" style="width: 100%; padding: 0; height: 30px;  text-align: center;" class="ui-fluid" type="text" />
            </td>
            <td style="text-align:center">
              <button *ngIf="editingLine!==ri" pButton type="button" icon="pi pi-trash" class="ui-button-info"
                      (click)="editingLine=ri"></button>
              <button *ngIf="editingLine===ri" pButton type="button" icon="pi pi-check"
                      class="ui-button-success"
                      style="margin-right: .5em" (click)="deleteLine(ri)"></button>
              <button *ngIf="editingLine===ri" pButton type="button" icon="pi pi-times" class="ui-button-danger"
                      (click)="editingLine=-1"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </ng-container>
  <ng-container>
    <p-tabPanel header="DA306">
      <ng-template pTemplate="content">
        <div class="ui-g-12 ui-g-nopad">
          <p-toolbar>
            <div class="ui-toolbar-group-left ui-g-9">
            </div>
            <div class="ui-toolbar-group-right">
              <div class="ui-md-12 ui-g-12">
                <button pButton type="button" label="Generate DA306"
                        [disabled]="disableButton"
                        (click)="generateDA306()"></button>
                <button *ngIf="pdfSrcc" pButton type="button" label="Print/Download" (click)="openLink()"></button>
              </div>
            </div>
          </p-toolbar>
          <div *ngIf="disableButton" class="ui-g-12">
            <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
          </div>
          <div class="card card-w-title">
            <div class="ui-g">
              <pdf-viewer class="ui-g-12 ui-md-12"
                          *ngIf="pdfSrcc"
                          [src]="pdfSrcc"
                          [render-text]="true"
                          [original-size]="true"
                          [show-all]="true"
                          [stick-to-page]="true"
                          (after-load-complete)="disableButton=false"
              ></pdf-viewer>
            </div>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>
  </ng-container>
  <ng-container>
    <p-tabPanel [header]="isTransportMethodCodeSameAs('air') ? 'Air Track Trace' : ''">
      <ng-template pTemplate="content">
        <div class="ui-g-12 ui-g-nopad">
          <button class="ui-button" (click)="navigateToSite()">Track Shipment</button>
        </div>
      </ng-template>
    </p-tabPanel>
  </ng-container>
</p-tabView>

