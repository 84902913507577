<div class="card card-w-title">
  <div class="ui-g">
    <div class="ui-g-11">
        <button *ngIf="indicator==='imports' && !rcgMode || indicator==='exports' && !rcgMode" type="button" pButton icon="fa fa-search" style="margin-right: 10px;" (click)="openSearchCriteriaModal()"
                label="File Enquiry"></button>
        <p-checkbox *ngIf="indicator==='imports'" binary="true"
                    name="rcgMode"
                    value="rcgMode"
                    label="RCG Clearing Files?"
                    (onChange)="loadRcgBoes($event)"></p-checkbox>

            <span *ngIf="indicator==='imports' && rcgMode" class="md-inputfield" style="margin-top: 22px;">
              <input type="text" pInputText
                     [(ngModel)]="mawbFilter">
              <label>Search MAWB</label>
              <button pButton label="Search" style="margin: 0 10px 0 10px;" (click)="loadBillOfEntries()"></button>

              <p-checkbox *ngIf="indicator==='imports' && canSubmitToSars()" binary="true"
                          name="filterRejections"
                          value="filterRejections"
                          label="Rejections Only"
                          (onChange)="loadRcgRejectionBoes($event)"></p-checkbox>
            </span>
    </div>
    <div class="ui-g-1">
      <button *ngIf="canSubmitToSars()" pButton label="Submit EDI" (click)="submitBoesToSars()"></button>
    </div>
  </div>
  <h1 style="text-align: center">SARS Messages</h1>
  <p-table [value]="billsOfEntry" [paginator]="true" [rows]="10" [lazy]="true" [lazyLoadOnInit]="true"
           [loading]="loading"
           (onLazyLoad)="onLazyLoad($event, indicator)"
           [totalRecords]="totalRecords"
           [columns]="cols"
           [responsive]="true">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns let-ediMessage>
      <tr>
        <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-ediMessage>
      <tr>
        <td><a (click)="loadClearingFile(ediMessage?.clearingFileNumber, ediMessage?.sequenceNumber)">{{ediMessage?.clearingFileNumber}}</a></td>
        <td>{{ediMessage?.sequenceNumber}}</td>
        <td class="truncate wrap">{{ediMessage?.lrn}}</td>
        <td class="truncate wrap">{{ediMessage?.cusres?.mrn}}</td>
        <td class="truncate wrap">{{ediMessage?.customsPurposeCode}}</td>
        <td>{{ediMessage?.dateEdiSubmitted}}</td>
        <td style="text-align: center;">
          <i class="pi pi-check" [ngClass]="{'pi': true, 'pi-check-circle': mdnResponseStatus(ediMessage), 'pi-times-circle': !mdnResponseStatus(ediMessage)}">
          </i>
        </td>
        <td>{{ediMessage?.contrl?.receivedDate}}</td>
        <td style="text-align: center;">
          <i class="pi pi-check" [ngClass]="{'pi': true, 'pi-check-circle': controlStatus(ediMessage), 'pi-times-circle': !controlStatus(ediMessage)}">
          </i>
        </td>
        <td>{{ediMessage?.cusres?.receivedDate}}</td>
        <td>{{ getStatus(ediMessage) | replaceUnderScores}}</td>
      </tr>
    </ng-template>
  </p-table>
  <p-dialog header="Search for File"
            [(visible)]="displaySearchModal"
            [modal]="true"
            [responsive]="true"
            [width]="1300"
            [minWidth]="200"
            [minY]="100"
            [baseZIndex]="10000"
            (onHide)="closeModal()">
    <div *ngIf="loading" class="ui-g-12">
      <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
    </div>
    <div class="ui-g-6 ui-md-6 ui-fluid">
      <p-panel header="Criteria">
        <div class="ui-g form-group">
          <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="mawbNo">
                          <label>MAWB No. / MBOL No.</label>
                        </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                  <p-autoComplete id="importerName"
                                  [suggestions]="filteredBusinessEntities"
                                  [ngModel]="businessEntity" field="label"
                                  (onSelect)="selectedImporterOrExporterBusinessEntity($event)" [minlength]="3"
                                  (completeMethod)="searchBusinessEntity($event)"
                                  [forceSelection]="true"
                                  [autoHighlight]="true">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.code}}:{{option.name}}</div>
                    </ng-template>
                  </p-autoComplete>
                 <label>{{indicator === 'imports' ? 'Importer Name':'Exporter Name'}}</label>
                </span>
                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>
            </span>
          </div>

          <div *ngIf="indicator==='imports'" class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                  <p-autoComplete id="importerCode"
                                  [suggestions]="filteredBusinessEntities"
                                  [ngModel]="businessEntity" field="label"
                                  (onSelect)="selectedImporterOrExporterBusinessEntity($event)" [minlength]="8"
                                  (completeMethod)="searchImporterEntityCode($event)">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.code}}:{{option.name}}</div>
                    </ng-template>
                  </p-autoComplete>
                 <label>Importer Code</label>
                </span>
                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>
            </span>
          </div>

          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <div class="ui-inputgroup">
                <span class="ui-float-label">
                  <p-autoComplete id="supplierName"
                                  [suggestions]="filteredBusinessEntities"
                                  [ngModel]="businessEntity" field="label"
                                  (onSelect)="selectedSupplierBusinessEntity($event)" [minlength]="3"
                                  (completeMethod)="searchBusinessEntity($event)"
                                  [forceSelection]="true"
                                  [autoHighlight]="true">
                    <ng-template let-option pTemplate="item">
                      <div>{{option.code}}:{{option.name}}</div>
                    </ng-template>
                  </p-autoComplete>
                 <label>Supplier Name</label>
                </span>
                <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
              </div>
            </span>
          </div>

          <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="fileNo">
                          <label>File No.</label>
                        </span>
            </div>
          </div>

          <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="referenceNo">
                          <label>Reference No.</label>
                        </span>
            </div>
          </div>


          <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="containerNo">
                          <label>Container No.</label>
                        </span>
            </div>
          </div>


          <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="lrnNo"
                                 maxlength="25"
                                 >
                          <label>LRN No.</label>
                        </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="mrnNo"
                                 maxlength="18"
                                 >
                          <label>MRN No. / BOE No.</label>
                        </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="caseNo"
                                 >
                          <label>CASE No.</label>
                        </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="supplierInvNo"
                                 >
                          <label>Supplier Invoice No.</label>
                        </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                  <p-autoComplete [(ngModel)]="selectedClientInvoice" field="invoiceNo"
                                  [suggestions]="filteredInvoices"
                                  (completeMethod)="search($event)"
                                  [autoHighlight]="true" minLength="1"
                                  (onSelect)="select($event)"
                                  [dropdown]="true"
                                  [forceSelection]="true"
                                  required=true>
                    <ng-template let-option pTemplate="item">
                      <div>{{option.invoiceNo}}</div>
                    </ng-template>
                  </p-autoComplete>
                <label>Client Invoice No.</label>
              </span>
              <span class="ui-inputgroup-addon" (click)="clearAll()" ><i class="fa fa-times"></i></span>
            </div>
          </span>
          </div>
          <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
                          <span class="ui-float-label">
                             <span class="md-inputfield">
                              <p-inputMask mask="99/99/9999"
                                           slotChar="dd/mm/yyyy"
                                           [(ngModel)]="fromDate"
                              >
                              </p-inputMask>
                              <label>From (Date)</label>
                             </span>
                          </span>
                        </span>
          </div>
          <div class="ui-g-12 ui-md-12">
                        <span class="md-inputfield">
                          <span class="ui-float-label">
                             <span class="md-inputfield">
                              <p-inputMask mask="99/99/9999"
                                           slotChar="dd/mm/yyyy"
                                           [(ngModel)]="toDate"
                              >
                              </p-inputMask>
                              <label>To (Date)</label>
                             </span>
                          </span>
                        </span>
          </div>
        </div>
      </p-panel>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-search" (click)="searchBoes()" label="Search"
              class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>

</div>
<p-confirmDialog header="Confirmation"
                 icon="pi pi-exclamation-triangle"
                 width="425"
                 [rejectLabel]="'No'"
                 [acceptLabel]="'Yes'"
></p-confirmDialog>
