import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ForwardingFileBehaviourSubject } from '../../../subjects/forwarding-file-behaviour-subject';
import { TransportDocumentBehaviourSubject } from '../../../subjects/transport-document-behaviour-subject';
import { ContainerBehaviorSubject } from '../../../subjects/container-behavior-subject';
import { ShareDataService } from './share-data.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
import * as i3 from "../../../subjects/forwarding-file-behaviour-subject";
import * as i4 from "../../../subjects/transport-document-behaviour-subject";
import * as i5 from "../../../subjects/container-behavior-subject";
import * as i6 from "./share-data.service";
var ForwardingFileService = /** @class */ (function () {
    function ForwardingFileService(http, config, forwardingFileBehaviourSubject, transportDocumentBehaviourSubject, containerBehaviorSubject, shareDataService) {
        this.http = http;
        this.config = config;
        this.forwardingFileBehaviourSubject = forwardingFileBehaviourSubject;
        this.transportDocumentBehaviourSubject = transportDocumentBehaviourSubject;
        this.containerBehaviorSubject = containerBehaviorSubject;
        this.shareDataService = shareDataService;
    }
    ForwardingFileService.prototype.save = function (forwardingFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (forwardingFile._links && forwardingFile._links.self) {
            return this.http.put(this.config.filesApiEndpoint + 'api/forwardingFiles/' + forwardingFile.id, JSON.stringify(forwardingFile), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/forwardingFiles', JSON.stringify(forwardingFile), { headers: headers });
        }
    };
    ForwardingFileService.prototype.getStatusCodesAndEvents = function () {
        return this.http.get(this.config.filesApiEndpoint + 'api/forwardingFiles/tracking');
    };
    ForwardingFileService.prototype.sendParcelUpdates = function (references, status) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.put(this.config.filesApiEndpoint + 'api/forwardingFiles/tracking/' + references + '/' + status, { headers: headers });
    };
    ForwardingFileService.prototype.loadForwardingFile = function (fileNumber, transportMethodCode) {
        if (fileNumber === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/forwardingFiles/search/fileNumberStartsWith?fileNumber=" + fileNumber + "&transportMethodCode=" + transportMethodCode)
            .pipe(map(function (response) { return response['_embedded'].forwardingFiles; }));
    };
    ForwardingFileService.prototype.loadForwardingFilefromMawb = function (mawbNumber) {
        if (mawbNumber === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/forwardingFiles/search/findByMawbNo?mawbNo=" + mawbNumber)
            .pipe(map(function (response) { return response['_embedded'].forwardingFiles; }));
    };
    ForwardingFileService.prototype.createNewFile = function (transportMethod) {
        this.forwardingFileBehaviourSubject.addForwardingFile(this.shareDataService.buildForwardingFile(transportMethod));
        this.containerBehaviorSubject.addContainer(this.shareDataService.addContainer());
    };
    ForwardingFileService.prototype.selectForwardingFile = function (value) {
        this.createNewFile();
        this.forwardingFileBehaviourSubject.addForwardingFile(value);
        if (value.houseBills.length > 0) {
            this.transportDocumentBehaviourSubject.addTransportDocument(value.houseBills[0]);
            if (value.houseBills[0].containers && value.houseBills[0].containers.length > 0) {
                this.containerBehaviorSubject.addContainer(value.houseBills[0].containers[0]);
            }
        }
    };
    ForwardingFileService.prototype.generate = function (id) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/forwardingFiles/" + id + "/documents", {});
    };
    ForwardingFileService.prototype.generateHawbReport = function (fileId, hawbFilter) {
        return this.http.get(this.config.filesApiEndpoint + "api/financialReports/hawbReport?fileId=" + fileId + "&hawbFilter=" + hawbFilter);
    };
    ForwardingFileService.prototype.findByFileNoAndSequence = function (fileNumber, sequence) {
        if (fileNumber === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/forwardingFiles/search/fileNumberStartsWith?fileNumber=" + fileNumber)
            .pipe(map(function (response) { return response['_embedded'].forwardingFiles; }));
    };
    ForwardingFileService.prototype.createFromSpreadsheet = function (file, transportMethodCode) {
        var formData = new FormData();
        formData.append('file', file);
        return this.http.post(this.config.filesApiEndpoint + 'api/forwardingFiles/csvUpload?transportMethodCode=' + transportMethodCode, formData);
    };
    ForwardingFileService.prototype.updateFromSpreadsheet = function (id, file) {
        var formData = new FormData();
        formData.append('file', file);
        return this.http.put(this.config.filesApiEndpoint + ("api/forwardingFiles/" + id + "/csvUpload"), formData);
    };
    ForwardingFileService.prototype.createClearingFilesFromHawbs = function (id) {
        var formData = new FormData();
        return this.http.post(this.config.filesApiEndpoint + ("api/forwardingFiles/clearingFromForwardingFile?id=" + id), formData);
    };
    ForwardingFileService.prototype.findByMawbNo = function (mawbNo) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/forwardingFiles/search/findByMawbNo?mawbNo=" + mawbNo)
            .pipe(map(function (response) { return response['_embedded'].forwardingFiles; }));
    };
    ForwardingFileService.prototype.findByForwardingFileNo = function (fileNumber) {
        var url = this.config.filesApiEndpoint + "api/forwardingFiles/search/findByForwardingFileNo?fileNumber=" + fileNumber;
        return this.http.get(url);
    };
    ForwardingFileService.prototype.getAllForwardingFiles = function (page, size) {
        var url = this.config.filesApiEndpoint + "api/forwardingFiles/forwarding-files?page=" + page + "&size=" + size;
        return this.http.get(url);
    };
    ForwardingFileService.prototype.getTotalForwardingFilesCount = function () {
        var url = this.config.filesApiEndpoint + "api/forwardingFiles/forwarding-files/total-count";
        return this.http.get(url);
    };
    ForwardingFileService.prototype.createListOfHousebills = function (files) {
        var housebills = [];
        files.forEach(function (file) {
            file.houseBills.forEach(function (h) {
                var customHouseBill = Object.assign({}, h);
                customHouseBill.mawbNo = file.masterBill.mawbNo;
                customHouseBill.mawbDate = file.masterBill.mawbDate;
                customHouseBill.flight = file.masterBill.flight;
                customHouseBill.departureDate = file.masterBill.departureDate;
                customHouseBill.arrivalDate = file.masterBill.arrivalDate;
                customHouseBill.airline = Object.assign({}, file.masterBill.airline);
                customHouseBill.forwardFile = { forwardingFileNumber: file.fileNumber };
                customHouseBill.depotTerminal = file.masterBill.depot;
                customHouseBill.customsOffice = Object.assign({}, file.customOffice);
                customHouseBill.localForwarder = file.masterBill.localForwarder;
                housebills.push(customHouseBill);
            });
        });
        return housebills;
    };
    ForwardingFileService.prototype.getHouseBillByHouseBillNumber = function (houseBillNo) {
        if (houseBillNo === '') {
            return of(null);
        }
        var parameters = new HttpParams().set('houseBillNo', houseBillNo);
        return this.http.get(this.config.filesApiEndpoint + "api/forwardingFiles/search/findByHousebillNo", { params: parameters });
    };
    ForwardingFileService.prototype.validateHouseBills = function (houseBills) {
        return this.http
            .post(this.config.filesApiEndpoint + "api/forwardingFiles/search/validateHawbNos", houseBills);
    };
    ForwardingFileService.prototype.updateScannedHouseBills = function (houseBills, status) {
        return this.http.put(this.config.filesApiEndpoint + ("api/forwardingFiles/updateHouseBills/" + status), houseBills);
    };
    ForwardingFileService.prototype.findForwardingFileNumbersByHawbNo = function (hawbNo, fileNumber) {
        return this.http.get(this.config.filesApiEndpoint + "api/forwardingFiles/search/findForwardingFileNumbersByHawbNo?forwardingFileNumber=" + fileNumber + "&hawbNo=" + hawbNo)
            .pipe(map(function (response) { return response.map(function (el) { return el.fileNumber; }); }));
    };
    ForwardingFileService.prototype.loadFiles = function (id) {
        var url = this.config.filesApiEndpoint + "api/forwardingFiles/" + id + '/digiScan';
        return this.http.get(url);
    };
    ForwardingFileService.prototype.generateDA306 = function (id) {
        return this.http.get(this.config.filesApiEndpoint + "api/forwardingFiles/" + id + '/da306');
    };
    ForwardingFileService.ngInjectableDef = i0.defineInjectable({ factory: function ForwardingFileService_Factory() { return new ForwardingFileService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG), i0.inject(i3.ForwardingFileBehaviourSubject), i0.inject(i4.TransportDocumentBehaviourSubject), i0.inject(i5.ContainerBehaviorSubject), i0.inject(i6.ShareDataService)); }, token: ForwardingFileService, providedIn: "root" });
    return ForwardingFileService;
}());
export { ForwardingFileService };
