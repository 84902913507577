import {BusinessEntityReference} from './permit';
import {PackType} from './roadManifest';
import {ShipmentType} from './enumerations';
import {Uom} from './TariffCode';
import {ShippingOrder} from './exportFile';
import {Package} from './voyageFile';
import {Importer} from './clearingFile';

export class File {
  id ?: string;
  clearingFileNumber?: string;
  reference?: Reference;
  clearingInstructions?: ClearingInstruction[];
  debtor?: Debtor;
  incoTerm?: IncoTerm;
  localAgent?: LocalAgent;
  supplier?: Supplier;
  customOffice?: CustomOffice;
  type?: string;
  assessmentDate?: string;
  shippingDescription?: string;
  slaDate?: string;
  corrections?: string;
  sequence?: number;
  exporterReference?: string;
  consigneeReference?: string;
  branch?: string;
  shippingOrder?: ShippingOrder;
  quotation?: boolean;
  fromForwarding?: boolean;
  invoicingInUse?: boolean;

  [k: string]: any;
}

export class Correction extends File {
  reasons?: string;
  number?: number;
  sequence?: number;
  cancellation?: boolean;
  claimVat?: boolean;
  liquidationOfProvisionalPayment?: boolean;
  parent?: any;

  [k: string]: any;
}

export class BusinessEntity {
  name?: string;
  code?: string;
  address?: Address;
  label?: string;
}

export class Reference {
  businessUnit?: string;
  referenceFileNo?: string;
  channel?: string;
  businessReference?: string;

  [k: string]: any;
}

export class ClearingInstruction {
  procedureCategoryCode?: string;
  customVatRequired?: boolean;
  customsProcedureCode?: number;
  previousCustomsProcedureCode?: number;
  previousMrnNumber?: string;
  destinationWarehouse?: Warehouse;
  destination?: Country;
  customsProcDesc?: string;
  consolidateBoeLines?: boolean;
  pcDescription?: string;
  transportMethod?: TransportMethod;
  customsPurpose?: CustomsPurpose;
  rebateUser?: RebateUser;
  removalTransportMethod?: TransportMethod;
  portDetails?: PortDetail;
  degroupingDepot?: DepotTerminal;
  customOffice?: CustomOffice;
  warehouse?: Warehouse;
  remover?: Remover;
  guarantor?: Guarantor;
  consignee?: Consignee;
  importerConsignee?: Consignee;
  toImporterConsignee?: Consignee;
  exporterConsignor?: Consignor;
  owner?: Importer;
  uuid?: string;

  [k: string]: any;
}

export class CustomsPurpose {
  customsPurposeCode?: string;
  customsPurposeName?: string;
  customsPurposeFile?: CustomsPurposeFile;
  label?: string;

}

export class TradeAgreement {
  code?: string;
  formattedCode?: string;
  description?: string;

  [k: string]: any;
}

export class TransportMethod {
  code?: string;
  method?: string;
  description?: string;

  [k: string]: any;
}

export class RebateUser {
  code?: string;
  address?: Address;
  name?: string;
  customsCode?: string;
  label?: string;

  [k: string]: any;
}

export class Warehouse {
  code?: string;
  address?: Address;
  name?: string;
  customsWarehouseNumber?: string;
  label?: string;

  [k: string]: any;
}

export class Remover {
  code?: string;
  address?: Address;
  name?: string;
  customsCode?: string;
  bondValue?: number;
  label?: string;
  roleType?: string;

  [k: string]: any;
}

export class Guarantor {
  code?: string;
  name?: string;
  address?: Address;
  guaranteeType?: GuaranteeType;
  bondValue?: number;
  percentageUnderSplit?: string;
  label?: string;
  roleType?: string;
  customsCode?: string;

  [k: string]: any;
}

export class Consignee {
  code?: string;
  name?: string;
  address?: Address;
  label?: string;
  roleType?: string;
  customsWarehouseNumber?: string;
  customsCode?: string;
  taxRegNo?: string;

  [k: string]: any;
}

export class Consignor extends Consignee {}

export class Address {
  zipCode?: string;
  line4?: string;
  line3?: string;
  line2?: string;
  line1?: string;

  [k: string]: any;
}

export class IncoTerm {
  incoTermDesc?: string;
  incoTermCode?: string;

  [k: string]: any;
}

export class LocalAgent {
  code?: string;
  address?: Address;
  name?: string;
  customsOfficeCreditors?: LocalAgentCreditor[];
  customsOfficeBonds?: LocalAgentBond[];
  registrations?: LocalAgentRegistrations[];
  localAgentCreditors?: LocalAgentCreditor[];
  label?: string;
  customsRegCode?: string;
  iataCode?: string;
  inhouseAgent?: boolean;

  [k: string]: any;
}

export class LocalAgentBond {
  customsOffice?: CustomOffice;
  bondNo?: string;
  expiryDate?: string;
}

export class CustomOffice {
  districtOfficeCode?: string;
  districtOfficeName?: string;
  label?: string;

  [k: string]: any;
}

export class Debtor {
  code?: string;
  address?: Address;
  name?: string;
  label?: string;
  roleType?: number;

  [k: string]: any;
}

export class SupplierInvoice implements HasSundryCharge {
  quantity?: number;
  valueBasedCharges?: ValueBasedCharge[];
  userInterfaceDefaults?: UserInterfaceDefaults;
  invoiceDate?: string;
  totalGrossIncomeValue?: number;
  sequence?: number;
  runningTotals?: RunningTotals;
  apportionWeightOnLines?: boolean;
  manualExWarehouseLineCapture?: boolean;
  supplier?: Supplier;
  invoiceNumber?: string;
  defaultValues?: DefaultValues;
  currency?: Currency;
  rateBasedCharges?: RateBasedCharge[];
  lines?: SupplierInvoiceLine[];
  ucrNumber ?: string;
  ucrCreditTerms ?: string;
  certificate?: Certificate;
  ucr?: UniqueConsignmentReference;

  [k: string]: any;
}

export class Certificate {
  certificateNo?: string;
  type?: CertificateType;
  label?: string;
}

export class CertificateType {
  code?: string;
  description?: string;
}

export class UserInterfaceDefaults {
  autoClientOrderNumberPart?: boolean;
  standardTradeAgreement?: boolean;
  quickPart?: boolean;
  defaultAllDependencies?: boolean;
  autoRepeat?: boolean;
  tariffMode?: boolean;
  autoClientOrderNumberClientRef?: boolean;

  [k: string]: any;
}

export class RunningTotals {
  remainingQuantity?: number;
  capturedValue?: number;
  remainingValue?: number;
  capturedQuantity?: number;

  [k: string]: any;
}

export class ContainerRunningTotals {
  remainingGrossWeight?: number;
  capturedValue?: number;
  remainingValue?: number;
  capturedGrossWeight?: number;
  remainingNoOfPackages?: number;
  capturedNoOfPackages?: number;
  remainingVolume?: number;
  capturedVolume?: number;

  [k: string]: any;
}

export class Supplier {
  invoiceDate?: string;
  invoiceNumber?: string;
  vdnNumber?: string;
  defaultValuationCode?: string;
  defaultValuationMethod?: number;
  code?: string;
  address?: Address;
  vdnMarkup?: number;
  name?: string;
  vdnWarning?: boolean;
  customsCode?: string;
  label?: string;
  defaultPurchaseTerm?: PurchaseTerm;
  roleType?: number;

  [k: string]: any;

}

export class DefaultValues {
  country?: Country;
  rooNumber?: string;
  purchaseTerm?: PurchaseTerm;

  [k: string]: any;
}

export class Country {
  code?: string;
  defaultTradeAgreement?: TradeAgreement;
  name?: string;
  tradeAgreements?: TradeAgreement[];
  label?: string;

  [k: string]: any;
}

export class PurchaseTerm {
  code?: string;
  description?: string;
  label?: string;

  [k: string]: any;
}

export class Currency {
  code?: string;
  name?: string;
  label?: string;
  exchangeRate?: number;

  [k: string]: any;
}

export class AdditionalUom {
  uomCode?: string;
  value?: number;
}

export class SupplierInvoiceLine {
  repair?: boolean;
  invoiceGrossValue?: number;
  description?: string;
  fullDescription?: string;
  used?: boolean;
  invoiceValue?: number;
  lineQuantity?: number;
  da59?: boolean;
  lineNo?: number;
  tradeAgreement?: TradeAgreement;
  lineWeight?: number;
  rooNumber?: string;
  isRooRequired?: boolean;
  additionalScheduleTariffs?: TariffCode[];
  noChargeItem?: boolean;
  tariffCode?: TariffCode;
  vehicleDetails?: VehicleDetails;
  usedGoods?: boolean;
  customsVAT?: boolean;
  discountPercent?: number;
  customsValue?: number;
  userInterfaceCheckbox?: UserInterfaceCheckbox;
  additionalClientReference?: string;
  toBeCoded?: string;
  quantity?: number;
  unitPrice?: number;
  quantityType?: string;
  vdnPercent?: number;
  excludeFromCosting?: boolean;
  partNumber?: string;
  countryOfOrigin?: Country;
  discountValue?: number;
  permit?: Permit;
  clientOrderNo?: string;
  procedureCategoryCode?: number;
  ucrNumber ?: string;
  ucrCreditTerms?: string;
  ucrTransactionValueCurrency?: string;
  ucrTransactionValue ?: number;
  instructionIndex ?: number;
  instructionUUID?: string;
  certificate?: Certificate;
  ucr?: UniqueConsignmentReference;
  additionalUoms?: AdditionalUom[];
  costingValue?: number;
  costingUom?: Uom;
  noOfPacks?: number;
  itemsPerPack?: number;
  caseNumber?: string;
  packType?: PackType;
  previousBoeDetails?: PreviousBoeDetails;

  [k: string]: any;
}

export class SchedulePart {
  code?: string;
  description?: string;
  value?: number;

  [k: string]: any;
}

export class PurposeSchedulePart {
  schedulePart?: string;
  customsPurposeCode?: string;
}

export class FormulaSchedulePart {
  computations?: FormulaComputation[];
  rebate?: boolean;
  schedulePart?: string;
  description?: string;
  hasDcc?: boolean;
  number?: number;
  rebateExcludes12B?: boolean;
  chargeString?: string;
  noOfParameters?: number;
  prodatFormulaNo?: string;
  formulaNo?: number;
  free?: boolean;
  hasRcc?: boolean;
  parameters?: FormulaParameter[];
  effectiveDate?: string;

  [k: string]: any;
}

export class UserInterfaceCheckbox {
  quickPart?: boolean;
  tariffMode?: boolean;

  [k: string]: any;
}

export class Document implements HasSundryCharge {
  flight?: string;
  hawbNo?: string;
  cargoCarrierCode?: string;
  departurePort?: PortDetail;
  docsIssuedAtPort?: PortDetail;
  dischargePort?: PortDetail;
  freight?: Freight;
  customsValue?: CustomsValue;
  clearingFileNumber?: string;
  subMAWBNo?: string;
  mawbNo?: string;
  clientRefNo?: string;
  arrivalDate?: string;
  depotTerminal?: DepotTerminal;
  destinationPort?: PortDetail;
  finalDestinationPort?: PortDetail;
  straight?: boolean;
  marksAndNumbers?: string[];
  measures?: Measures;
  endorsements?: string[];
  departureDate?: string;
  airline?: Airline;
  hawbDate?: string;
  mawbDate?: string;
  rateBasedCharges?: RateBasedCharge[];
  valueBasedCharges?: ValueBasedCharge[];
  oceanLineDetails?: OceanLineDetails;
  voyageDetails?: VoyageDetails;
  containers?: Container[];
  containerRunningTotals?: ContainerRunningTotals;
  validateMawbNo ?: boolean;
  validateHawbNo ?: boolean;
  duplicateHawbNo ?: boolean;
  transporter?: Transporter;
  forwardFile?: ForwardFile;
  supplierInvoices?: SupplierInvoice[];
  localForwarder?: LocalForwarder;
  vesselsAgent?: VesselsAgent;
  reservationDate?: string;
  terminal?: DepotTerminal;
  depot?: DepotTerminal;
  packages?: Package[];
  [k: string]: any;
}

export class TransportDocument extends Document {
  postalClearing?: boolean;
  previousBOEDetails?: PreviousBoeDetails;
}

export class CrewMember {
  id ?: string;
  label?: string;
  firstNames: string;
  surname: string;
  crewMember?: boolean;
  driver?: boolean;
  passenger?: boolean;
  foreign: boolean;
  idNumber?: string;
  passportNumber: string;
  travelDocType?: string;
  gender?: string;
  reasonForMovement?: string;
  nationality?: Country;
  countryOfResidence?: Country;
  travelDocCountryOfIssue?: Country;
  travelDocExpiryDate?: string;
  dateOfBirth?: string;
  occupation?: string;
  role?: string;
  [k: string]: any;
}

export class RouteDetails {
  destinationPort?: PortDetail;
  departurePort?: PortDetail;
  airline?: Airline;
  flightNo?: string;
  flightDate?: string;
}

export class RateClass {
  code?: string;
  name?: string;
}

export class Commodity {
  code?: string;
  description?: string;
}

export class PackageDimensions {
  quantity?: number;
  length?: number;
  width?: number;
  height?: number;
  volumetricWeight?: number;
}

export class PackageDetails {
  rateClass?: RateClass;
  hawbRate?: number;
  iataRate?: number;
  commodity?: Commodity;
  description?: string;
  actualWeight?: number;
  units?: string;
  hazardous?: boolean;
  refrigerated?: boolean;
  asAgreed?: boolean;
  packageType?: PackType;
  volumetricWeight?: number;
  chargeableWeight: number;
  dimensions?: PackageDimensions[];
}

export class Information {
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
}

export class ExportTransportDocument extends Document {
  exitPort?: PortDetail;
  callPurpose?: string;
  notifyPartyName?: string;
  notifyPartyAddress?: Address;
  coLoader?: LocalAgent;
  overseasAgent?: OverseasAgent;
  stackOpen?: string;
  stackClose?: string;
  exchangeRateDate?: string;
  routes?: RouteDetails[];
  packageDetails?: PackageDetails[];
  handlingInformation?: Information;
  accountingInformation?: Information;
  shipmentType?: ShipmentType;
}

export class ForwardFile {
  forwardingFileNumber?: string;
  externalUniqueId?: string;
}

export class Transporter {
  transporterCode?: string;
  transporterName?: string;
  customsRegCode?: string;
  label?: string;

}

export class ContainerReleasedTo {
  code?: string;
  name?: string;
  label?: string;
  roleType?: string;
}

export class VoyageDetails {
  customsRadioCallSign?: CustomsRadioCallSign;
  voyageNo?: string;
  remover?: Remover;
  vehicleAndTrailerReg?: string;
  trailerOne?: string;
  trailerTwo?: string;
  railName?: string;
  railCode?: string;
}

export class ContainerType {
  code?: string;
  description?: string;
  cargoStatusCode?: number;
}

export class CustomsRadioCallSign {
  radioCallSign?: string;
  vesselName?: string;
  shippingLineCode?: string;
  shippingLineName?: string;
}

export class OceanLineDetails {
  carrierCode?: OceanLine;
  oceanLine?: OceanLine;

  [k: string]: any;
}

export class OceanLine {
  code?: string;
  name?: string;
  label?: string;

  [k: string]: any;
}

export class Freight {
  amount?: number;
  currency?: Currency;
  collectPrepaid?: CollectPrepaid;

  [k: string]: any;
}

export class CustomsValue {
  amount?: number;
  localAmount?: number;
  currency?: Currency;
  invoiceDate?: string;
  invoiceNumber?: string;

  [k: string]: any;
}

export class CollectPrepaid {
  code?: string;
  description?: string;

  [k: string]: any;
}

export class PreviousBoeDetails {
  date?: string;
  customsBOENo?: string;
  customOffice?: CustomOffice;
  cpc?: string;
  portTo?: PortTo;
  sequenceNo?: number;
  customsDuty?: number;
  customsVAT?: number;
  customsValue?: number;
  localCustomsValue?: number;
  invoiceGrossValue?: number;
  localInvoiceGrossValue?: number;
  actualValue?: number;
  noOfPacks?: number;
  remainingPacks?: number;
  lineQuantity?: number;
  lineWeight?: number;

  [k: string]: any;
}

export class CustomsProcedure {
  customsProcedureCode?: string;
  customsProcDesc?: string;
}
export class PortTo {
  code?: string;
  name?: string;

  [k: string]: any;
}

export class DepotTerminal {
  code?: string;
  name?: string;
  label?: string;

  [k: string]: any;
}

export class PortDetail {
  code?: string;
  name?: string;
  countryName?: string;
  countryCode?: string;
  transportMethod?: string;
  masPortCode?: string;
  label?: string;

  [k: string]: any;
}

export class Measures {
  volume?: number;
  grossWeight?: number;
  nettWeight?: number;
  chargeableWeight?: number;
  numberOfPackages?: number;
  noOfContainers?: number;
  numberOfTrucks?: number;

  [k: string]: any;
}

export class Solas {
  vgm?: number;
  verificationMethod?: number;
  vgmVerifier?: string;
  samasaAccrediation?: string;
}

export class MasContainerType {
  type?: string;
  description?: string;
  additionalInformation?: string;
}

export class MasContainerSize {
  code?: string;
  description?: string;
}

export class Container {
  containerType?: ContainerType;
  nonIsoContainerNo?: boolean;
  containerNo?: string;
  serialNo?: string;
  noOfPackages?: number;
  zone?: string;
  solas?: Solas;
  nettWeight?: number;
  cbm?: number;
  freightTons?: number;
  containerSpecification?: ContainerSpecification;
  sealDetails?: SealDetails;
  masContainerType?: MasContainerType;
  masContainerSize?: MasContainerSize;
  terminal?: DepotTerminal;
  depot?: DepotTerminal;
  turnInFree?: boolean;
  storageDate?: Date;
  unpackDate?: Date;
  containerReleasedTo?: ContainerReleasedTo;
  cargoType?: CargoType;
}

export class CargoType {
  code?: string;
  description?: string;
  label ?: string;
}

export class SealDetails {
  sealingParty?: string;
  sealStatus?: string;
  sealType?: string;
}

export class ContainerSpecification {
  code?: string;
  description?: string;
  tareWeight?: number;
  maxWeight?: number;
  capacity?: number;
  containerSize?: ContainerSize;
}

export class ContainerSize {
  size?: number;
  code?: string;
  description?: string;
  label?: string;
  maxMass?: number;
  nettMass?: number;
}

export class LocalForwarder {
  name?: string;
  cargoCarrierCode?: string;
  transportMethod?: string;

  [k: string]: any;
}

export class VesselsAgent {
  name?: string;
  code?: string;
  [k: string]: any;
}

export class Airline {
  code?: string;
  prefix?: string;
  name?: string;

  [k: string]: any;
}

export class VehicleDetails {
  vinNumber?: string;
  engineNumber?: string;
  colour?: string;
  model?: number;
}

export class ValueBasedCharge {
  insurance?: boolean;
  amount?: number;
  sundryCharge?: SundryCharge;
  dutiable?: boolean;
  freight?: boolean;
  inlandDutiable?: boolean;
  nonDutiable?: boolean;
  discount?: boolean;
  currency?: Currency;
  inlandDestinationCountry?: boolean;
  collect?: boolean;
  inlandNonDutiable?: boolean;

  [k: string]: any;
}

export class RateBasedCharge {
  amount?: number;
  dutiable?: boolean;
  rate?: number;
  nonDutiable?: boolean;
  currency?: Currency;
  collect?: boolean;
  sundryCharge?: SundryCharge;
  baseAmount?: number;

  [k: string]: any;
}

export class SundryCharge {
  code?: string;
  description?: string;

  [k: string]: any;
}

export class TariffCode {
  parameters?: TariffParameter[];
  formula?: TariffFormula;
  checkDigit?: Number;
  fullDescription?: string;
  tariffSDesc?: string;
  code?: string;
  description?: string;
  schedule?: string;
  tariff?: boolean;
  tariffID?: number;
  dependantCode?: string;
  dependantCheckDigit?: string;
  schedulePart: SchedulePart;
  scheduleDescription?: string;
  calculatedValue?: number;
  category?: string;
  customsDuty?: number;
  atv?: number;
  vat?: number;
  totalPayable?: number;
  line?: string;

  [k: string]: any;
}

export class TariffFormula {
  statisticalUOM?: string;
  description?: string;
  effectiveDate?: string;
  computations?: FormulaComputation[];
  rebate?: Boolean;
  parameters?: FormulaParameter[];
  hasDcc?: Boolean;
  number?: Number;
  rebateExcludes12B?: Boolean;
  chargeString?: string;
  prodatFormulaNo?: string;
  free?: Boolean;
  hasRcc?: Boolean;
  noOfParameters?: Number;

  [k: string]: any;
}

export class TariffParameter {
  tradeGroupType?: string;
  number?: string;
  tradeGroupCode?: string;
  tariffParameterNo?: Number;
  effectiveDate?: string;
  tariffId?: Number;
  value2?: string;
  value1?: string;

  [k: string]: any;
}

export class FormulaParameter {
  number?: Number;
  incInRebate?: string;
  formulaNo?: Number;
  dataType?: string;
  value?: string;

  [k: string]: any;
}

export class FormulaComputation {
  number?: Number;
  formulaNo?: Number;
  computationBase?: string;
  consolidateAllowed?: Boolean;
  computation?: string;

  [k: string]: any;
}

export interface HasSundryCharge {
  rateBasedCharges?: RateBasedCharge[];
  valueBasedCharges?: ValueBasedCharge[];
}

export class CustomsPurposeFile {
  documentCode?: string;
  fileTypeId?: number;
  customsPurposeCode?: string;
  vocDocumentCode?: string;

  [k: string]: any;
}

export class LocalAgentCreditor {
  districtOfficeCode?: string;
  financialAccountNumber?: string;
  customsBoxNumber?: string;
  paymentDay?: number;
  startDay?: number;
  endDay?: number;
  deferredLimit?: number;
  warningLimit?: number;
  deferredBalance?: number;
  inDeferredPool?: boolean;
  paymentType?: PaymentType;
}

export class LocalAgentRegistrations {
  localAgentCode?: string;
  transportMethod?: string;
  customsRegCode?: string;
  customsBoxNo?: string;
  masRegCode?: string;
  cargoCarrierCode?: string;
}

export class PaymentType {
  paymentCode?: string;
  paymentDesc?: string;

  [k: string]: any;
}

export class Permit {
  businessEntityReference?: BusinessEntityReference;
  balanceQuantity?: number;
  appliedQuantity?: number;
  valueBased?: boolean;
  permitNumber?: string;
  uom?: string;
  balanceValue?: number;
  quantityBased?: boolean;
  appliedValueForeign?: number;
  appliedValueLocal?: number;
}

export class GuaranteeType {
  code?: string;
  description?: string;

  [k: string]: any;
}

export class UniqueConsignmentReference {
  number?: string;
  date?: string;
  businessEntityCode?: string;
  businessEntityRole?: string;
  entityType ?: string;
  referenceType ?: string;
  customsCode ?: string;
  referenceNumber ?: string;
  country ?: Country;
  transactionCurrency?: Currency;
  advancedPaymentCurrency ?: Currency;
  paymentTerm?: UCRPaymentTerm;
  transactionValue ?: number;
  advancedPaymentValue ?: string;
  scope ?: string;
  id?: string;
}

export class UCRPaymentTerm {
  code?: string;
  description?: string;
  label?: string;
}

export class OverseasAgent {
  code?: string;
  name?: string;
  address?: Address;
  customsCode?: string;
  label?: string;

}
