var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { BillOfEntryMarksAndNumbers } from '../../../models/billOfEntries';
import { Address, CollectPrepaid, Currency, Freight, PortDetail, TariffCode, CustomsValue, Supplier } from '../../../models/file';
import { BusinessEntityService } from '../../../../digi-business-entity/services/business-entity.service';
import { ForwardingFileBehaviourSubject } from '../../../../../subjects/forwarding-file-behaviour-subject';
import { AgentService } from '../../../services/agent.service';
import { PackTypeService } from '../../../services/pack-type.service';
import { NotificationService } from '../../../services/notification.service';
import { ForwardingFileValidationService } from '../../../services/forwarding-file-validation.service';
import { MasterBillMeasures, RunningTotals, TrackingDetails, ForwardingTransportDocument, TransShipmentDetails, DigiScan } from '../../../models/ForwardingFile';
import { DepotTerminalService } from '../../../services/depot-terminal.service';
import { TariffService } from '../../../services/tariff.service';
import { ForwardingFileService } from '../../../services/forwarding-file.service';
import { TransportService } from '../../../../digi-air/services/transport.service';
import { GoodsDescription, Package } from '../../../models/voyageFile';
import { Importer } from '../../../models/clearingFile';
import { OceanlineService } from '../../../../digi-ocean/services/oceanline.service';
import { CustomsRadioCallSignService } from '../../../services/customs-radio-call-sign.service';
import { AppConfig } from '../../../../app.config.module';
import { Router } from '@angular/router';
import { ContainerBehaviorSubject } from '../../../../../subjects/container-behavior-subject';
import { ShareDataService } from '../../../services/share-data.service';
import { TransportDocumentBehaviourSubject } from '../../../../../subjects/transport-document-behaviour-subject';
import { CurrencyService } from '../../../services/currency.service';
import { CompanyService } from '../../../services/company.service';
import { CustomsTraderType } from '../../../../digi-business-entity/models/business-entity';
import { ImporterService } from '../../../services/importer.service';
var ManifestsComponent = /** @class */ (function () {
    function ManifestsComponent(companyService, importerService, currencyService, shareDataService, transportDocumentBehaviourSubject, containerBehaviorSubject, messageService, forwardingFileBehaviourSubject, forwardingFileService, notificationService, localAgentService, depotTerminalService, businessEntityService, transportService, packTypeService, tariffService, oceanLineService, customsRadioCallSignService, config, router, forwardingFileValidationService) {
        this.companyService = companyService;
        this.importerService = importerService;
        this.currencyService = currencyService;
        this.shareDataService = shareDataService;
        this.transportDocumentBehaviourSubject = transportDocumentBehaviourSubject;
        this.containerBehaviorSubject = containerBehaviorSubject;
        this.messageService = messageService;
        this.forwardingFileBehaviourSubject = forwardingFileBehaviourSubject;
        this.forwardingFileService = forwardingFileService;
        this.notificationService = notificationService;
        this.localAgentService = localAgentService;
        this.depotTerminalService = depotTerminalService;
        this.businessEntityService = businessEntityService;
        this.transportService = transportService;
        this.packTypeService = packTypeService;
        this.tariffService = tariffService;
        this.oceanLineService = oceanLineService;
        this.customsRadioCallSignService = customsRadioCallSignService;
        this.config = config;
        this.router = router;
        this.forwardingFileValidationService = forwardingFileValidationService;
        this.required = true;
        this.onSelect = new EventEmitter();
        this.onSelectUsed = false;
        this.activeIndex = 0;
        this.showTrackingProgressBar = false;
        this.display = false;
        this.showTrackingButton = false;
        this.validationMessagesForHawbNo = [];
        this.tableFirst = 0;
        this.filteredCurrencies = [];
        this.displayImporterModal = false;
        this.importerAddresses = [];
        this.editingLine = -1;
        // tslint:disable-next-line:max-line-length
        this.rows = [{ noOfItems: null, descriptionOfGoods: '', length: null, width: null, height: null, totalMass: null, volMass: null }];
    }
    ManifestsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.cols = [
            { header: 'HAWB No' },
            { header: 'Importer' },
            { header: 'Client Ref No' },
            { header: 'Amount' }
        ];
        if (this.config.enableTracking) {
            this.cols.push({ header: 'Tracking' });
        }
        this.createNewHawb();
        this.displayCustomsValue();
        this.displaySupplierInvoiceDetails();
        this.displayTariffCodesDetails();
        this.displayDutiesAndTaxes();
        this.forwardingFileServiceSubscription = this.forwardingFileBehaviourSubject.getForwardingFile()
            .subscribe(function (file) {
            _this.forwardingFile = file;
            _this.companyService.getCompany().subscribe(function (companies) {
                if (companies && companies.length) {
                    var branch_1 = companies[0].branches[0];
                    if (branch_1.invoicingInUse) {
                        _this.forwardingFile.houseBills.forEach(function (houseBill) {
                            houseBill.invoicingInUse = branch_1.invoicingInUse;
                        });
                    }
                }
            });
            if (_this.forwardingFile.houseBills) {
                if (_this.forwardingFile.houseBills.length > 0) {
                    var searchedHawb = _this.forwardingFile.houseBills.find(function (houseBill) { return houseBill.hawbNo === _this.selectedHouseBill.hawbNo; });
                    if (searchedHawb) {
                        _this.selectHouseBill(searchedHawb);
                    }
                    else {
                        _this.selectHouseBill(_this.forwardingFile.houseBills[0]);
                    }
                }
                else {
                    var transportDocument = _this.shareDataService.addTransportDocument('forwarding');
                    _this.forwardingFile.houseBills.push(transportDocument);
                    _this.forwardingFile.houseBills = _this.forwardingFile.houseBills.slice();
                    _this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
                }
                _this.showTrackingButton = true;
            }
            else {
                _this.createNewHawb();
                _this.forwardingFile.houseBills = [_this.selectedHouseBill];
                _this.selectHouseBill(_this.selectedHouseBill);
                _this.transportDocumentBehaviourSubject.addTransportDocument(_this.forwardingFile.houseBills[0]);
            }
            _this.houseBillSelection = _this.forwardingFile.houseBills;
            _this.companyService.getCompany().subscribe(function (companies) {
                if (companies && companies.length) {
                    var branch = companies[0].branches[0];
                    if (branch && branch.forwardingLocalAgentCode && branch.forwardingLocalAgentCode.trim() !== '') {
                        _this.localAgentService
                            .findLocalAgentNameOrCodeStartsWith(branch.forwardingLocalAgentCode)
                            .subscribe(function (data) {
                            if (data) {
                                _this.forwardingFile.houseBills.forEach(function (houseBill) {
                                    if (!houseBill.localAgent) {
                                        houseBill.localAgent = data[0];
                                        houseBill.localAgent.address = data[0].addresses[0];
                                    }
                                });
                            }
                        });
                    }
                }
            });
        });
        this.tdSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
            .subscribe(function (td) {
            if (td) {
                _this.selectedHouseBill = td;
            }
        });
    };
    ManifestsComponent.prototype.setTrackingStatus = function () {
        this.currentStatus = 'No Status Available';
        if (this.forwardingFile.masterBill) {
            if (this.forwardingFile.masterBill.trackingDetails) {
                if (this.forwardingFile.masterBill.trackingDetails.receivedAtJNBOPS) {
                    this.currentStatus = 'Received At JNB OPS';
                }
                else if (this.forwardingFile.masterBill.trackingDetails.receivedAtORTIA) {
                    this.currentStatus = 'Received At Airport';
                }
                else if (this.forwardingFile.masterBill.trackingDetails.releaseDate) {
                    this.currentStatus = 'Received At Customs';
                }
                else if (this.forwardingFile.masterBill.trackingDetails.submissionDate) {
                    this.currentStatus = 'Sent To Customs';
                }
            }
        }
    };
    ManifestsComponent.prototype.track = function (transportDocument, isHouseBill) {
        this.setTrackingStatus();
        this.transportDocumentToTrack = transportDocument;
        if (!this.transportDocumentToTrack.trackingDetails) {
            this.transportDocumentToTrack.trackingDetails = new TrackingDetails();
        }
        if (this.forwardingFile.contrl) {
            this.transportDocumentToTrack.trackingDetails.submissionDate = this.forwardingFile.contrl.receivedDate;
        }
        if (this.forwardingFile.cusres) {
            this.transportDocumentToTrack.trackingDetails.releaseDate = this.forwardingFile.cusres.receivedDate;
        }
        this.isHouseBill = isHouseBill;
        this.displayTrackingDialog = true;
    };
    ManifestsComponent.prototype.createNewHawb = function () {
        this.selectedHouseBill = new ForwardingTransportDocument();
        this.selectedHouseBill.measures = new MasterBillMeasures();
        this.selectedHouseBill.freight = new Freight();
        this.selectedHouseBill.customsValue = new CustomsValue();
        this.selectedHouseBill.customsValue.currency = new Currency();
        this.selectedHouseBill.importer = new Importer();
        this.selectedHouseBill.importer.address = new Address();
        this.selectedHouseBill.importer.registrationType = new CustomsTraderType();
        this.selectedHouseBill.supplier = new Supplier();
        this.selectedHouseBill.tariffCodes = [new TariffCode()];
        this.selectedHouseBill.freight.collectPrepaid = new CollectPrepaid();
        this.selectedHouseBill.freight.currency = new Currency();
        this.selectedHouseBill.departurePort = new PortDetail();
        this.selectedHouseBill.destinationPort = new PortDetail();
        this.selectedHouseBill.notifyPartyAddress = new Address();
        this.selectedHouseBill.transShipmentDetails = new TransShipmentDetails();
        this.selectedHouseBill.transShipmentDetails.transferPort = new PortDetail();
        this.selectedHouseBill.digiScan = new DigiScan();
        this.selectedHouseBill.containers = new Array();
    };
    ManifestsComponent.prototype.handleIndexChange = function (index) {
        if (index === 0) {
            this.validateAndSaveForwardingFile();
        }
        if (index === 1) {
            this.updateRunningTotals();
            this.validateAndSaveForwardingFile();
        }
        if (index === 2) {
            this.validateAndSaveForwardingFile();
        }
        if (index === 3) {
            this.validateAndSaveForwardingFile();
        }
    };
    ManifestsComponent.prototype.createNewFile = function () {
        if (this.router.url === '/forwarding/sea/manifests') {
            this.forwardingFileService.createNewFile('SEA');
        }
        if (this.router.url === '/forwarding/air/manifests') {
            this.forwardingFileService.createNewFile('AIR');
        }
    };
    ManifestsComponent.prototype.addNewHawb = function () {
        this.createNewHawb();
        if (this.forwardingFile.houseBills) {
            this.forwardingFile.houseBills.push(this.selectedHouseBill);
        }
        else {
            this.forwardingFile.houseBills = [this.selectedHouseBill];
        }
        this.houseBillSelection = this.forwardingFile.houseBills;
    };
    ManifestsComponent.prototype.searchHousebill = function () {
        var _this = this;
        var searchedHawb = this.forwardingFile.houseBills.find(function (houseBill) { return houseBill.hawbNo === _this.hawbFilter; });
        if (searchedHawb) {
            this.selectHouseBill(searchedHawb);
            this.houseBillSelection = [searchedHawb];
        }
        else {
            this.houseBillSelection = this.forwardingFile.houseBills;
            this.selectHouseBill(this.forwardingFile.houseBills[0]);
            this.tableFirst = 0;
        }
    };
    ManifestsComponent.prototype.searchingHousebill = function () {
        var _this = this;
        var searchedHawb = this.forwardingFile.houseBills.find(function (houseBill) { return houseBill.hawbNo === _this.selectedHouseBill.digiScan.scanHawb; });
        if (searchedHawb) {
            this.selectedHouseBill.digiScan.importer = searchedHawb.importer;
            this.selectedHouseBill.digiScan.supplier = searchedHawb.supplier;
            this.selectedHouseBill.digiScan.customerReference = this.forwardingFile.masterBill.mawbNo;
            this.selectedHouseBill.digiScan.noOfItems = searchedHawb.packages[0].noOfPackages;
            this.selectedHouseBill.digiScan.descriptionOfGoods = searchedHawb.packages[0].description;
            // tslint:disable-next-line:max-line-length
            if (!searchedHawb.digiScan.specialInstruction || searchedHawb.digiScan.specialInstruction !== this.selectedHouseBill.digiScan.specialInstruction) {
                searchedHawb.digiScan.specialInstruction = this.selectedHouseBill.digiScan.specialInstruction;
            }
            if (!searchedHawb.digiScan.length || searchedHawb.digiScan.length !== this.selectedHouseBill.digiScan.length) {
                searchedHawb.digiScan.length = this.selectedHouseBill.digiScan.length;
            }
            if (!searchedHawb.digiScan.width || searchedHawb.digiScan.width !== this.selectedHouseBill.digiScan.width) {
                searchedHawb.digiScan.width = this.selectedHouseBill.digiScan.width;
            }
            if (!searchedHawb.digiScan.height || searchedHawb.digiScan.height !== this.selectedHouseBill.digiScan.height) {
                searchedHawb.digiScan.height = this.selectedHouseBill.digiScan.height;
            }
            if (!searchedHawb.digiScan.totalMass || searchedHawb.digiScan.totalMass !== this.selectedHouseBill.digiScan.totalMass) {
                searchedHawb.digiScan.totalMass = this.selectedHouseBill.digiScan.totalMass;
            }
            if (!searchedHawb.digiScan.volMass || searchedHawb.digiScan.volMass !== this.selectedHouseBill.digiScan.volMass) {
                searchedHawb.digiScan.volMass = this.selectedHouseBill.digiScan.volMass;
            }
            this.forwardingFile.masterBill.scanHawb = this.selectedHouseBill.digiScan.scanHawb;
            this.forwardingFile.masterBill.specialInstruction = this.selectedHouseBill.digiScan.specialInstruction;
        }
    };
    ManifestsComponent.prototype.saveDigiForwardingFile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var searchedHawb;
            return __generator(this, function (_a) {
                this.searchingHousebill();
                searchedHawb = this.forwardingFile.houseBills.find(function (houseBill) { return houseBill.hawbNo === _this.selectedHouseBill.digiScan.scanHawb; });
                this.forwardingFileService.save(this.forwardingFile).subscribe(function (forwardingFile) {
                    _this.messageService.successNotify('File Saved Successfully');
                    _this.forwardingFileBehaviourSubject.addForwardingFile(forwardingFile);
                    _this.selectHouseBill(searchedHawb);
                });
                return [2 /*return*/];
            });
        });
    };
    ManifestsComponent.prototype.clearHawbSearch = function () {
        this.houseBillSelection = this.forwardingFile.houseBills;
        this.selectHouseBill(this.forwardingFile.houseBills[0]);
        this.hawbFilter = undefined;
        this.tableFirst = 0;
    };
    ManifestsComponent.prototype.generateReport = function () {
        var _this = this;
        this.showProgressBar = true;
        // tslint:disable-next-line:max-line-length
        this.hawbReportsSubscription = this.forwardingFileService.generateHawbReport(this.forwardingFile.id, this.hawbFilter ? this.hawbFilter : null).subscribe(function (data) {
            _this.generatedFiles = data._embedded.supportingDocuments;
            _this.showDialog();
            _this.showProgressBar = false;
        }, function (error) {
            _this.showProgressBar = false;
            _this.messageService.errorNotify(error.error.message, error.error.errors);
        });
    };
    ManifestsComponent.prototype.selectHouseBill = function (value) {
        this.selectedHouseBill = value;
        this.transportDocumentBehaviourSubject.addTransportDocument(value);
        if (!this.selectedHouseBill.marksNumbersAndEndorsement) {
            var marksAndNumbers = new BillOfEntryMarksAndNumbers();
            marksAndNumbers.marksAndNumbers = [];
            marksAndNumbers.endorsements = [];
            this.selectedHouseBill.marksNumbersAndEndorsement = [marksAndNumbers];
        }
        if (!this.selectedHouseBill.packages || (this.selectedHouseBill.packages && !this.selectedHouseBill.packages.length)) {
            this.addNewPackage();
        }
        else {
            this.selectedPackage = this.selectedHouseBill.packages[0];
        }
        if (this.selectedHouseBill.containers && !this.selectedHouseBill.containers.length) {
            this.addContainer();
        }
        if (!this.selectedHouseBill.digiScan) {
            this.digiScan = new DigiScan();
            this.selectedHouseBill.digiScan = this.digiScan;
        }
    };
    ManifestsComponent.prototype.searchAirline = function (event) {
        var _this = this;
        this.transportService.findAllAirLineStartsWith(event.query).subscribe(function (data) {
            _this.filteredAirlines = data;
        });
    };
    ManifestsComponent.prototype.selectAirline = function (value) {
        this.forwardingFile.masterBill.airline = value;
        this.forwardingFile.masterBill.airline.prefix = value.airlinePrefix;
    };
    ManifestsComponent.prototype.selectMasterAirline = function (value) {
        this.forwardingFile.masterBill.masterAirline = value;
        this.forwardingFile.masterBill.masterAirline.prefix = value.airlinePrefix;
    };
    ManifestsComponent.prototype.selectOverseasAgent = function (value) {
        this.forwardingFile.masterBill.overseasAgent = value;
    };
    ManifestsComponent.prototype.selectCoLoader = function (value) {
        this.forwardingFile.masterBill.coLoader = value;
    };
    ManifestsComponent.prototype.searchLocalForwarder = function (event) {
        var _this = this;
        this.localAgentService
            .findLocalForwarderByNameOrCargoCarrierCodeAndTransportMethod(event.query, 'AIR')
            .subscribe(function (data) {
            _this.filteredLocalForwarders = data;
        });
    };
    ManifestsComponent.prototype.searchDepotTerminal = function (event, depotTerminal) {
        var _this = this;
        this.depotTerminalService.findAllDepotTerminalStartsWith(event.query, depotTerminal).subscribe(function (data) {
            _this.filteredDepotTerminals = data;
        });
    };
    ManifestsComponent.prototype.updateCustomsOffice = function (customOffice) {
        this.forwardingFile.customOffice = customOffice;
    };
    ManifestsComponent.prototype.selectWarehouse = function (value) {
        this.forwardingFile.warehouse = value;
    };
    ManifestsComponent.prototype.onRowDelete = function (rowIndex) {
        this.forwardingFile.houseBills.splice(rowIndex, 1);
        this.forwardingFile.houseBills = this.forwardingFile.houseBills.slice();
    };
    ManifestsComponent.prototype.selectImporter = function (value) {
        this.selectedHouseBill.importer = value;
    };
    ManifestsComponent.prototype.select = function (value) {
        if (this.onSelectUsed) {
            this.onSelect.emit(value);
        }
        else {
            this.currency.code = value.code;
            this.currency.name = value.name;
        }
    };
    ManifestsComponent.prototype.selectDigiScanImporter = function (value) {
        this.selectedHouseBill.digiScan.importer = value;
    };
    ManifestsComponent.prototype.selectLocalAgent = function (value) {
        this.selectedHouseBill.localAgent = value;
    };
    ManifestsComponent.prototype.selectSupplier = function (value) {
        this.selectedHouseBill.supplier = value;
    };
    ManifestsComponent.prototype.selectDigiScanSupplier = function (value) {
        this.selectedHouseBill.digiScan.supplier = value;
    };
    ManifestsComponent.prototype.selectDebtor = function (value) {
        this.selectedHouseBill.debtor = value;
    };
    ManifestsComponent.prototype.selectHouseOverseas = function (value) {
        this.selectedHouseBill.overseasAgent = value;
    };
    ManifestsComponent.prototype.openImporterModal = function () {
        this.displayImporterModal = true;
        this.selectedHouseBill.importer.isEdited = true;
    };
    ManifestsComponent.prototype.onImporterCreated = function (businessEntity) {
        var importer = new Importer();
        this.displayImporterModal = false;
        if (businessEntity && businessEntity.code) {
            this.importers.find(function (i) {
                if (i.code === businessEntity.code) {
                    importer = i;
                    return true;
                }
                return false;
            });
            this.onSelectImporter(importer);
        }
    };
    ManifestsComponent.prototype.onSelectImporter = function (importer) {
        var _this = this;
        this.importerService.findImporterNameOrCodeStartsWith(importer.name)
            .subscribe(function (result) {
            _this.importerAddresses = result[0].addresses;
        });
    };
    ManifestsComponent.prototype.updateMarksAndNumbers = function (value) {
        this.selectedHouseBill.marksNumbersAndEndorsement = value;
    };
    ManifestsComponent.prototype.addNewPackage = function () {
        this.selectedPackage = new Package();
        this.selectedPackage.goodsDescription = new GoodsDescription();
        this.selectedHouseBill.packages = [this.selectedPackage];
    };
    ManifestsComponent.prototype.addContainer = function () {
        var container = this.shareDataService.addContainer();
        this.selectedHouseBill.containers.push(container);
        this.selectedHouseBill.containers = this.selectedHouseBill.containers.slice();
        this.containerBehaviorSubject.addContainer(container);
    };
    ManifestsComponent.prototype.searchPackTypes = function (event) {
        var _this = this;
        this.packTypeService.findByCodeOrDescription(event.query).subscribe(function (data) { return _this.filteredPackTypes = data; });
    };
    ManifestsComponent.prototype.selectPackType = function (value) {
        this.selectedPackage.packageType = value;
    };
    ManifestsComponent.prototype.searchTariffCode = function (event) {
        var _this = this;
        this.tariffService
            .findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(event.query)
            .subscribe(function (data) {
            _this.filteredTariffCodes = data;
        });
    };
    ManifestsComponent.prototype.selectTariffCode = function (value) {
        this.selectedPackage.tariffCode = value;
    };
    ManifestsComponent.prototype.showDialog = function () {
        this.display = true;
    };
    ManifestsComponent.prototype.updateRunningTotals = function () {
        this.forwardingFile.masterBill.runningTotals = new RunningTotals();
        this.forwardingFile.masterBill.runningTotals.capturedNoOfHouseBills = this.forwardingFile.houseBills.length;
        this.forwardingFile.masterBill.runningTotals.remainingNoOfHouseBills =
            this.forwardingFile.masterBill.measures.numberOfHawbs -
                (this.forwardingFile.masterBill.runningTotals.capturedNoOfHouseBills ?
                    this.forwardingFile.masterBill.runningTotals.capturedNoOfHouseBills : 0);
        // noOfPacks
        this.forwardingFile.masterBill.runningTotals.capturedNoOfPacks = Math.round(this.forwardingFile.houseBills
            .map(function (hawb) { return hawb.measures.numberOfPackages; })
            .reduce(function (sum, current) { return current ? +sum + +current : +sum + 0; }, 0) * 100) / 100;
        // volume
        this.forwardingFile.masterBill.measures.volume = Math.round(this.forwardingFile.houseBills
            .map(function (hawb) { return hawb.measures.volume; })
            .reduce(function (sum, current) { return current ? +sum + +current : +sum + 0; }, 0) * 100) / 100;
        this.forwardingFile.masterBill.runningTotals.remainingNoOfPacks = Math.round((this.forwardingFile.masterBill.measures.numberOfPackages -
            (this.forwardingFile.masterBill.runningTotals.capturedNoOfPacks ?
                this.forwardingFile.masterBill.runningTotals.capturedNoOfPacks : 0)) * 100) / 100;
        // weight
        this.forwardingFile.masterBill.runningTotals.capturedWeight = Math.round(this.forwardingFile.houseBills
            .map(function (hawb) { return hawb.measures.grossWeight; })
            .reduce(function (sum, current) { return current ? +sum + +current : +sum; }, 0) * 100) / 100;
        this.forwardingFile.masterBill.runningTotals.remainingWeight = Math.round((this.forwardingFile.masterBill.measures.grossWeight -
            (this.forwardingFile.masterBill.runningTotals.capturedWeight ?
                this.forwardingFile.masterBill.runningTotals.capturedWeight : 0)) * 100) / 100;
        // chargeableWeight
        this.forwardingFile.masterBill.runningTotals.capturedChargeableWeight = this.forwardingFile.houseBills
            .map(function (hawb) { return hawb.measures.chargeableWeight; })
            .reduce(function (sum, current) { return current ? +sum + +current : +sum; }, 0);
        // noOfContainers
        this.forwardingFile.masterBill.measures.noOfContainers = Math.round(this.forwardingFile.houseBills
            .map(function (hawb) { return hawb.measures.noOfContainers; })
            .reduce(function (sum, current) { return current ? +sum + +current : +sum + 0; }, 0) * 100) / 100;
        this.forwardingFile.masterBill.runningTotals.remainingChargeableWeight =
            this.forwardingFile.masterBill.measures.chargeableWeight -
                (this.forwardingFile.masterBill.runningTotals.capturedChargeableWeight ?
                    this.forwardingFile.masterBill.runningTotals.capturedChargeableWeight : 0);
    };
    ManifestsComponent.prototype.selectFiles = function (event) {
        var files = event.originalEvent.srcElement.files;
        this.file = files[0];
    };
    ManifestsComponent.prototype.uploadManifest = function () {
        var _this = this;
        this.showProgressBar = true;
        if (this.forwardingFile && this.forwardingFile.id) {
            this.forwardingFileService.updateFromSpreadsheet(this.forwardingFile.id, this.file).subscribe(function (file) {
                _this.forwardingFileBehaviourSubject.addForwardingFile(file);
                _this.showProgressBar = false;
            });
        }
        else {
            this.forwardingFileService.createFromSpreadsheet(this.file, this.forwardingFile.transportMethodCode).subscribe(function (file) {
                _this.forwardingFileBehaviourSubject.addForwardingFile(file);
                _this.showProgressBar = false;
            }, function (error) {
                _this.showProgressBar = false;
            });
        }
    };
    ManifestsComponent.prototype.isTransportMethodCodeSameAs = function (code) {
        return this.forwardingFile && this.forwardingFile.transportMethodCode.toLowerCase() === code;
    };
    ManifestsComponent.prototype.searchOceanLines = function (event) {
        var _this = this;
        this.oceanLineService.findAllOceanLineStartsWith(event.query).subscribe(function (data) {
            _this.filteredOceanLines = data;
        });
    };
    ManifestsComponent.prototype.searchRadioCallSigns = function (event, field) {
        var _this = this;
        this.customsRadioCallSignService.findAllCustomsRadioCallSignStartsWith(event.query, field).subscribe(function (data) {
            _this.filteredCustomsRadioCallSigns = data;
        });
    };
    ManifestsComponent.prototype.saveForwardingFile = function () {
        var _this = this;
        this.forwardingFileService.save(this.forwardingFile).subscribe(function (forwardingFile) {
            _this.messageService.successNotify('File Saved Successfully');
            _this.forwardingFileBehaviourSubject.addForwardingFile(forwardingFile);
        });
    };
    ManifestsComponent.prototype.saveForwardingFileAfterUpdatingTariffCodes = function () {
        var _this = this;
        var updatePromises = this.selectedHouseBill.tariffCodes.map(function (tariffCode) {
            return _this.tariffService.findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(tariffCode.code)
                .toPromise().then(function (data) {
                var matchingResult = data.find(function (result) { return result.code === tariffCode.code; });
                return matchingResult ? matchingResult : tariffCode;
            });
        });
        Promise.all(updatePromises).then(function (updatedTariffCodes) {
            _this.selectedHouseBill.tariffCodes = updatedTariffCodes;
            _this.forwardingFileService.save(_this.forwardingFile).subscribe(function (forwardingFile) {
                _this.messageService.successNotify('Tariff Codes Updated');
                _this.forwardingFileBehaviourSubject.addForwardingFile(forwardingFile);
            });
        });
    };
    ManifestsComponent.prototype.addTariffCode = function () {
        this.selectedHouseBill.tariffCodes.push(new TariffCode());
    };
    ManifestsComponent.prototype.removeTariffCode = function (index) {
        this.selectedHouseBill.tariffCodes.splice(index, 1);
    };
    ManifestsComponent.prototype.validateAndSaveForwardingFile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var validationResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.forwardingFileValidationService.validateManifests(this.forwardingFile, this.forwardingFile.transportMethodCode)];
                    case 1:
                        validationResult = _a.sent();
                        if (validationResult.isValid) {
                            this.saveForwardingFile();
                        }
                        else {
                            this.messageService.errorNotify('Validation Failed', validationResult.messages);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ManifestsComponent.prototype.updateReceivedAtORTIAFieldsOnEachHouseBill = function () {
        this.updateHouseBills(this.forwardingFile.masterBill.trackingDetails.receivedAtORTIA, 'airport');
    };
    ManifestsComponent.prototype.updateHouseBills = function (status, airportOpsIndicator) {
        var _this = this;
        if (this.forwardingFile.houseBills) {
            for (var _i = 0, _a = this.forwardingFile.houseBills; _i < _a.length; _i++) {
                var houseBill = _a[_i];
                if (!houseBill.trackingDetails) {
                    houseBill.trackingDetails = new TrackingDetails();
                }
                if (airportOpsIndicator === 'ops') {
                    houseBill.trackingDetails.receivedAtJNBOPS = status;
                }
                else {
                    houseBill.trackingDetails.receivedAtORTIA = status;
                }
            }
            this.forwardingFileService.save(this.forwardingFile).subscribe(function (_) {
                _this.messageService.successNotify('House Bills Updated');
                _this.setTrackingStatus();
            });
        }
    };
    ManifestsComponent.prototype.updateReceivedAtJNOPSFieldsOnEachHouseBill = function () {
        this.updateHouseBills(this.forwardingFile.masterBill.trackingDetails.receivedAtJNBOPS, 'ops');
    };
    ManifestsComponent.prototype.sendUpdate = function (status) {
        var _this = this;
        this.showTrackingProgressBar = true;
        var references = [];
        if (this.forwardingFile.houseBills) {
            for (var _i = 0, _a = this.forwardingFile.houseBills; _i < _a.length; _i++) {
                var houseBill = _a[_i];
                references.push(houseBill.hawbNo);
            }
            this.forwardingFileService.sendParcelUpdates(references, status)
                .subscribe(function (_) {
                _this.messageService.successNotify('Successfully Updated API.');
                _this.showTrackingProgressBar = false;
            });
        }
    };
    ManifestsComponent.prototype.validateHawbNo = function () {
        var _this = this;
        this.forwardingFileService.findForwardingFileNumbersByHawbNo(this.selectedHouseBill.hawbNo, this.forwardingFile.fileNumber).subscribe(function (res) {
            if (res.length > 0) {
                var message_1 = ' [' + _this.selectedHouseBill.hawbNo + ']' + ' is also used in [' + res.join(', ') + ']';
                var foundDetail = _this.validationMessagesForHawbNo.find(function (el) { return el.detail === message_1; });
                if (!foundDetail) {
                    _this.validationMessagesForHawbNo.push({ severity: 'warn', summary: 'Duplicate HAWB No.', detail: message_1 });
                }
            }
            else {
                _this.validationMessagesForHawbNo.length = 0;
            }
        }, function (_) { return _this.validationMessagesForHawbNo.length = 0; });
    };
    ManifestsComponent.prototype.getStatusAndEvents = function () {
        this.forwardingFileService.getStatusCodesAndEvents()
            .subscribe(function (_) { });
    };
    ManifestsComponent.prototype.search = function (event) {
        var _this = this;
        this.currencyService.findCurrencyStartWith(event.query).subscribe(function (data) {
            _this.filteredCurrencies = data;
        });
    };
    ManifestsComponent.prototype.displayCustomsValue = function () {
        var _this = this;
        this.displayCustomsValuePanel = false;
        this.companyService.getCompany().subscribe(function (companies) {
            if (companies && companies.length) {
                var branch = companies[0].branches[0];
                if (branch.customsValueInUse) {
                    _this.displayCustomsValuePanel = true;
                }
            }
        });
    };
    ManifestsComponent.prototype.displaySupplierInvoiceDetails = function () {
        var _this = this;
        this.displaySupplierInvoicePanel = false;
        this.companyService.getCompany().subscribe(function (companies) {
            if (companies && companies.length) {
                var branch = companies[0].branches[0];
                if (branch.supplierInvoiceInUse) {
                    _this.displaySupplierInvoicePanel = true;
                }
            }
        });
    };
    ManifestsComponent.prototype.displayTariffCodesDetails = function () {
        var _this = this;
        this.displayTariffCodesPanel = false;
        this.companyService.getCompany().subscribe(function (companies) {
            if (companies && companies.length) {
                var branch = companies[0].branches[0];
                if (branch.tariffCodesInUse) {
                    _this.displayTariffCodesPanel = true;
                }
            }
        });
    };
    ManifestsComponent.prototype.displayDutiesAndTaxes = function () {
        var _this = this;
        this.displayDutiesAndTaxesPanel = false;
        this.companyService.getCompany().subscribe(function (companies) {
            if (companies && companies.length) {
                var branch = companies[0].branches[0];
                if (branch.dutiesAndTaxesInUse) {
                    _this.displayDutiesAndTaxesPanel = true;
                }
            }
        });
    };
    ManifestsComponent.prototype.navigateToSite = function () {
        window.open('https://www.track-trace.com/aircargo', '_blank');
    };
    ManifestsComponent.prototype.ngOnDestroy = function () {
        this.forwardingFileServiceSubscription.unsubscribe();
        this.tdSubscription.unsubscribe();
    };
    ManifestsComponent.prototype.print = function () {
        window.print();
    };
    ManifestsComponent.prototype.deleteLine = function (index) {
        this.rows.splice(index, 1);
    };
    ManifestsComponent.prototype.addNewLine = function () {
        this.rows.push({
            noOfItems: null,
            descriptionOfGoods: '',
            length: null,
            width: null,
            height: null,
            totalMass: null,
            volMass: null
        });
    };
    // to calculate volume mass
    ManifestsComponent.prototype.calculateVMass = function (digiScan) {
        var length = Number(digiScan.length);
        var width = Number(digiScan.width);
        var height = Number(digiScan.height);
        if (!isNaN(length) && !isNaN(width) && !isNaN(height)) {
            return (length * width * height) / 5000;
        }
        else {
            return 0;
        }
    };
    ManifestsComponent.prototype.generateDA306 = function () {
        var _this = this;
        this.disableButton = true;
        this.forwardingFileService.generateDA306(this.forwardingFile.id).subscribe(function (data) {
            _this.pdfSrcc = data._embedded.supportingDocuments[0]._links.self.href + ("?date=" + Date.now());
        }, function (error) {
            _this.notificationService.errorNotify(error.error.message, error.error.errors);
            _this.pdfSrcc = null;
            _this.disableButton = false;
        });
    };
    ManifestsComponent.prototype.openLink = function () {
        window.open(this.pdfSrcc, '_blank');
    };
    ManifestsComponent.prototype.loadGeneratedFiles = function () {
        var _this = this;
        this.saveForwardingFile();
        this.disableButton = true;
        this.forwardingFileService.loadFiles(this.forwardingFile.id).subscribe(function (data) {
            _this.generatedFiles = data._embedded.supportingDocuments;
            _this.showFilesDialog = true;
            _this.disableButton = false;
        }, function (error) {
            _this.disableButton = false;
        });
    };
    Object.defineProperty(ManifestsComponent.prototype, "totalCustomsDuty", {
        get: function () {
            return this.selectedHouseBill.tariffCodes.reduce(function (sum, tariff) {
                return sum + (tariff.customsDuty || 0);
            }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManifestsComponent.prototype, "totalATV", {
        get: function () {
            return this.selectedHouseBill.tariffCodes.reduce(function (sum, tariff) {
                return sum + (tariff.atv || 0);
            }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManifestsComponent.prototype, "totalVAT", {
        get: function () {
            return this.selectedHouseBill.tariffCodes.reduce(function (sum, tariff) {
                return sum + (tariff.vat || 0);
            }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManifestsComponent.prototype, "totalPayable", {
        get: function () {
            return this.selectedHouseBill.tariffCodes.reduce(function (sum, tariff) {
                return sum + (tariff.totalPayable || 0);
            }, 0);
        },
        enumerable: true,
        configurable: true
    });
    return ManifestsComponent;
}());
export { ManifestsComponent };
